import uniq from 'lodash/uniq';
export default function getUserInterests(adTags) {
    if (adTags) {
        const { media, gnre, theme } = adTags;
        return uniq([]
            .concat(media ? Object.keys(media) : undefined, gnre ? Object.keys(gnre) : undefined, theme ? Object.keys(theme) : undefined)
            .filter(Boolean));
    }
    else {
        return [];
    }
}
