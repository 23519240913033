import { __awaiter } from "tslib";
import log from '../utils/log';
import { callFandomServiceWithBody } from 'pathfinder/utils/api';
import waitForConsent from 'utils/waitForConsent';
import { callFandomService } from 'sdk/utils/callFandomService';
import botCheck from 'utils/botCheck';
const ADMS = 'active-data-management-service';
export function postActiveDataAction(action) {
    return __awaiter(this, void 0, void 0, function* () {
        // do not call adms when bots are here
        if (botCheck()) {
            return true;
        }
        try {
            const response = yield callFandomServiceWithBody(`${ADMS}/validate/`, Object.assign({}, action));
            if (response.status === 200) {
                return true;
            }
        }
        catch (e) {
            log.error('Failed to post active data action', e);
        }
        return false;
    });
}
// example curl --request GET "http://dev.sjc-dev.k8s.wikia.net/active-data-management-service/profiles/"
// --header 'X-Wikia-Internal-Request: 1' --header "Cookie: fandom_global_id="
export function getActiveDataFromAPI() {
    return __awaiter(this, void 0, void 0, function* () {
        // do not call adms when bots are here
        if (botCheck()) {
            return {};
        }
        // ensure context is given, but not throw any errors
        try {
            yield waitForConsent();
        }
        catch (err) {
            log.debug('Consent not given, cannot contact active-data-management-system');
            return {};
        }
        // contact ADMS
        try {
            const response = yield callFandomService(`${ADMS}/profiles?cb=${Date.now()}`);
            if (response.status === 200) {
                const profile = (yield response.json());
                log.debug('active-data-management-system API response', profile);
                return profile;
            }
        }
        catch (e) {
            log.error('Failed to fetch active-data', e);
        }
        return {};
    });
}
