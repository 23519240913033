import { getCommunicationService } from 'utils/communicationService';
import { AD_ENGINE_BAB_DETECTION, AD_EVENT_AD_CLICKED, AD_EVENT_SLOT_LOADED, AD_EVENT_UAP_LOAD_STATUS, } from 'utils/communicationService/adEngineTypes';
import { isGlobalDebugMode } from 'utils/env';
import { adBlockDetected, adSlotUpdate } from 'sdk/store/contextSlice';
import store from '../../store/store';
import log from '../../utils/log';
import { sessionAddInteraction } from 'sdk/store/browserStorageSlice';
import { AdSlotStatus, InteractionType } from 'sdk/types';
function detectAdTemplateLoaded(event) {
    if (isGlobalDebugMode()) {
        log.debug('AdEngine event', event);
    }
    // nothing right now
}
function detectAdSlotLoaded(event) {
    if (isGlobalDebugMode()) {
        log.debug('AdEngine event', event);
    }
    // Leave it for debug purposes as per https://wikia-inc.atlassian.net/browse/ADEN-10923?focusedCommentId=525115
    // console.log('AD EVENT detectAdSlotLoaded', event);
    // sometimes there are other evengts that we do not support, but have the same type
    if ((event === null || event === void 0 ? void 0 : event.name) && (event === null || event === void 0 ? void 0 : event.state)) {
        let status;
        if (event.state === 'collapse') {
            status = AdSlotStatus.COLLAPSED;
        }
        if (event.state === 'success') {
            status = AdSlotStatus.LOADED;
        }
        if (event.state === 'blocked') {
            status = AdSlotStatus.BLOCKED;
        }
        if (status) {
            store.dispatch(adSlotUpdate({
                name: event.name,
                status,
            }));
        }
    }
}
function detectAdUapLoadStatus(event) {
    // as per https://wikia-inc.atlassian.net/browse/ADEN-10923?focusedCommentId=525115
    store.dispatch(adSlotUpdate({
        name: 'uap',
        status: event.isLoaded ? AdSlotStatus.LOADED : AdSlotStatus.COLLAPSED,
    }));
}
function adClicked() {
    store.dispatch(sessionAddInteraction(InteractionType.ADS));
}
function babDetection(event) {
    store.dispatch(adBlockDetected(event.detected));
}
export function registerSlotLifecycleHandlers() {
    const communicationService = getCommunicationService();
    if (communicationService) {
        log.debug('Start listening for Ad events');
        // FIXME: If we need to detect the templates, just uncomment this
        // communicationService.on(AD_EVENT_TEMPLATE_LOADED, detectAdTemplateLoaded);
        communicationService.on(AD_EVENT_SLOT_LOADED, detectAdSlotLoaded);
        communicationService.on(AD_EVENT_UAP_LOAD_STATUS, detectAdSlotLoaded);
        communicationService.on(AD_EVENT_AD_CLICKED, detectAdSlotLoaded);
        communicationService.on(AD_ENGINE_BAB_DETECTION, detectAdSlotLoaded);
    }
}
