// list of values ready to go in DW
// This is just a helper guide to understand what new values are correctly set
// up in the DW.
export var ENABLED_EVERYWHERE;
(function (ENABLED_EVERYWHERE) {
    ENABLED_EVERYWHERE["CATEGORY"] = "category";
    ENABLED_EVERYWHERE["EVENT"] = "event";
    ENABLED_EVERYWHERE["LABEL"] = "label";
    ENABLED_EVERYWHERE["ACTION"] = "action";
    ENABLED_EVERYWHERE["VALUE"] = "value";
    ENABLED_EVERYWHERE["NON_INTERACTIVE"] = "nonInteractive";
    ENABLED_EVERYWHERE["IS_DEBUG"] = "isDebug";
})(ENABLED_EVERYWHERE || (ENABLED_EVERYWHERE = {}));
export var DW_UNIQUE_ENABLED_VALUES;
(function (DW_UNIQUE_ENABLED_VALUES) {
    DW_UNIQUE_ENABLED_VALUES["EXPERIMENT_VARIANT"] = "experimentVariant";
    DW_UNIQUE_ENABLED_VALUES["SS_VERSION"] = "silverSurferVersion";
    DW_UNIQUE_ENABLED_VALUES["AD_PVUID"] = "pvUID";
    DW_UNIQUE_ENABLED_VALUES["BEACON_ID"] = "beaconId";
})(DW_UNIQUE_ENABLED_VALUES || (DW_UNIQUE_ENABLED_VALUES = {}));
const validValues = [...Object.values(DW_UNIQUE_ENABLED_VALUES), ...Object.values(ENABLED_EVERYWHERE)];
export default validValues;
