import { getWikiaBeaconId } from '@fandom/context';
import { SS_EVENTS } from 'fandom-frontend-ready/timing/registry';
import record from 'fandom-frontend-ready/timing/timings';
import getUserProfileModule from './modules/userProfile/getUserProfile';
import { getContext as getContextModule } from './modules/getContext';
import { getGalactusUserProfile as getGalactusUserProfileModule } from './modules/userProfile/getGalactusUserProfile';
import registerClientModule from './modules/registerClient';
import addInteractionModule from './modules/addInteraction';
import { addAction as addActionModule, addActiveDataAction as addActiveDataActionModule, getActionsForClient as getActionsModule, getAllActions as getAllActionsModule, } from './modules/actionsTracker';
import store from './store/store';
import { isCrossdomainFinished as isCrossdomainFinishedInStore, isCrossdomainDisabled as isCrossdomainDisabledInStore, isSilverSurferReady, } from './store/selectors';
import log from './utils/log';
import { ActionType } from './models/Action';
import { bootstrap, trackPageview as manuallyTrackPageview } from './modules/appLifecycle';
import { SS_VERSION } from '../utils/env';
import { getGlobalId as getGlobalIdModule } from './modules/globalId';
const api = {
    trackPageview: manuallyTrackPageview,
    getContext: getContextModule,
    registerClient: registerClientModule,
    getUserProfile: getUserProfileModule,
    addInteraction: addInteractionModule,
    ACTION: ActionType,
    addActiveDataAction: addActiveDataAction,
    addAction: addActionModule,
    getAllActions: getAllActionsModule,
    getActions: getActionsModule,
    getGalactusUserProfile: getGalactusUserProfileModule,
    isCrossdomainFinished: isCrossdomainFinished,
    isCrossdomainDisabled: isCrossdomainDisabled,
    isInitialized: isInitialized,
    isReady: isReady,
    getVersionHash: getVersionHash,
    getGlobalId: getGlobalIdModule,
};
// Public API DO NOT CHANGE
export const ACTION = ActionType;
export function trackPageview() {
    return manuallyTrackPageview();
}
export function registerClient(client) {
    return registerClientModule(client);
}
export function addActiveDataAction(token, name, type, payload) {
    return addActiveDataActionModule(token, name, type, payload);
}
export function addAction(token, name, type, payload) {
    return addActionModule(token, name, type, payload);
}
export function getActions(token, type) {
    return getActionsModule(token, type);
}
export function getAllActions(token) {
    return getAllActionsModule(token);
}
export function getUserProfile() {
    return getUserProfileModule();
}
export function addInteraction(type) {
    return addInteractionModule(type);
}
export function isCrossdomainFinished() {
    return isCrossdomainFinishedInStore(store.getState());
}
export function isCrossdomainDisabled() {
    return isCrossdomainDisabledInStore(store.getState());
}
export function isInitialized() {
    return isSilverSurferReady(store.getState());
}
export function isReady() {
    return isInitialized() && (isCrossdomainFinished() || isCrossdomainDisabled());
}
export function getVersionHash() {
    return SS_VERSION;
}
// Private API
export function getGalactusUserProfile(beacon) {
    return getGalactusUserProfileModule(beacon || getWikiaBeaconId());
}
export function getGlobalId(name, type = 'default') {
    return getGlobalIdModule(name, type);
}
export function getContext() {
    return getContextModule();
}
try {
    record(SS_EVENTS.SS_PRE_BOOTSTRAP);
    bootstrap();
}
catch (e) {
    log.error('SilverSurfer bootstrap failed', e);
}
export default api;
