import { __awaiter } from "tslib";
import { getCookieByName } from 'utils/getCookieByName';
import { Product } from '@fandom/context';
import { sendPageView } from 'sdk/storage/crossdomainStorage';
import store from '../../store/store';
import { sessionPageViewSaved } from '../../store/browserStorageSlice';
import { pageTrackEntryFromPageContext } from '../../models/PageTrackEntry';
import log from '../../utils/log';
import { getPageContext } from '../../store/selectors';
import waitForConsent from 'utils/waitForConsent';
/**
 * Send `PageContext` to cross domain storage
 */
export function trackCrossdomainPageView() {
    return __awaiter(this, void 0, void 0, function* () {
        const plc = getPageContext(store.getState());
        const pageEntry = pageTrackEntryFromPageContext(plc);
        // send crossdomain page page view
        sendPageView(pageEntry);
    });
}
export const VISITED_WIKI_IDS_COOKIE_NAME = 'fan_visited_wikis';
/**
 * Add local `PageContext` to local storage (add fallback until cross domain kicks in)
 */
export function trackLocalPageView() {
    return __awaiter(this, void 0, void 0, function* () {
        const plc = getPageContext(store.getState());
        const pageEntry = pageTrackEntryFromPageContext(plc);
        log.debug('Tracking local page', pageEntry);
        store.dispatch(sessionPageViewSaved(pageEntry));
        // IOS/Safari hack read visited wiki ids from cookie, update the list and save back to cookie
        try {
            yield waitForConsent();
            // check if we're on MW or discussions
            if (![Product.MEDIAWIKI, Product.DISCUSSIONS].includes(plc.product)) {
                log.debug('Fallback for visited wikis DISABLED - not MW or Discussions', plc.product);
                return;
            }
            // read from cookie
            let visitedWikiIds = (getCookieByName(VISITED_WIKI_IDS_COOKIE_NAME) || '').split(',');
            // add current wiki id if not already there
            if (!visitedWikiIds.includes(plc.productId)) {
                // limit to last 50 entries
                visitedWikiIds = visitedWikiIds.slice(-50);
                visitedWikiIds.push(plc.productId);
            }
            // save cookie back
            const age = 365 * 24 * 60 * 60; // 1 year
            // eslint-disable-next-line
            document.cookie = `${VISITED_WIKI_IDS_COOKIE_NAME}=${visitedWikiIds
                .filter(Boolean)
                .join(',')};path=/;domain=.fandom.com;max-age=${age};SameSite=None;Secure`; // 10 years
            log.debug('Fallback for visited wikis enabled, new values', visitedWikiIds);
        }
        catch (err) {
            log.debug('Consent not given, fallback cookie not set', err);
        }
    });
}
