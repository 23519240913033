// Actions and Pages
import { createSlice } from '@reduxjs/toolkit';
import { Status } from '../storage/crossdomain/types';
import { pageTrackEntryToPageContext } from '../models/PageTrackEntry';
const initialState = {
    session: {
        interactions: {},
        actions: [],
        pages: [],
    },
    history: {
        interactions: {},
        actions: [],
        pages: [],
    },
    serviceCache: {
        galactusUserProfile: {},
        activeData: {},
    },
    local: {
        status: Status.Initializing,
        size: {
            localStorageSize: -1,
            indexedDBSize: -1,
        },
    },
    crossdomain: {
        status: Status.Initializing,
        statusErrorMessage: null,
        size: {
            localStorageSize: -1,
            indexedDBSize: -1,
        },
        pagesFinished: false,
        actionsFinished: false,
        interactionsFinished: false,
    },
};
export const browserStorageSlice = createSlice({
    name: 'BrowserStorageSlice',
    initialState,
    reducers: {
        cacheGalactusUserProfile: (state, action) => {
            state.serviceCache.galactusUserProfile[action.payload.beaconId] = action.payload.profile;
        },
        cacheActiveData: (state, action) => {
            state.serviceCache.activeData = action.payload.activeData;
        },
        crossdomainStatus: (state, action) => {
            var _a;
            const crossdomainStatus = action.payload.status;
            // once we established that it is ready, we no longer need to change it
            if (state.crossdomain.status != Status.Ready) {
                state.crossdomain.status = crossdomainStatus;
                state.crossdomain.statusErrorMessage =
                    crossdomainStatus === Status.Off ? (_a = action.payload) === null || _a === void 0 ? void 0 : _a.statusErrorMessage : '';
            }
        },
        sessionPageViewSaved: (state, action) => {
            state.session.pages.push(pageTrackEntryToPageContext(action.payload));
        },
        sessionActionSaved: (state, action) => {
            state.session.actions.push(action.payload);
        },
        sessionAddInteraction: (state, action) => {
            var _a;
            const { interactions } = state.session;
            const count = (_a = interactions[action.payload]) !== null && _a !== void 0 ? _a : 0;
            interactions[action.payload] = count + 1;
            state.session.interactions = interactions;
        },
        updateCrossdomainInteractions: (state, action) => {
            state.history.interactions = action.payload;
            state.crossdomain.interactionsFinished = true;
            state.crossdomain.status = Status.Ready;
        },
        updateCrossdomainActions: (state, action) => {
            state.history.actions = action.payload;
            state.crossdomain.actionsFinished = true;
            state.crossdomain.status = Status.Ready;
        },
        updateCrossdomainPages: (state, action) => {
            state.history.pages = action.payload.map(pageTrackEntryToPageContext);
            state.crossdomain.pagesFinished = true;
            state.crossdomain.status = Status.Ready;
        },
        updateCrossdomainSize: (state, action) => {
            state.crossdomain.size = action.payload;
            state.crossdomain.status = Status.Ready;
        },
        updateLocalHistory: (state, action) => {
            var _a, _b, _c, _d;
            state.history.interactions = action.payload.interactions;
            state.history.pages = (_b = (_a = action.payload.pages) === null || _a === void 0 ? void 0 : _a.map(pageTrackEntryToPageContext)) !== null && _b !== void 0 ? _b : [];
            state.history.actions = (_d = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.actions) !== null && _d !== void 0 ? _d : [];
            state.local.status = Status.Ready;
        },
        updateLocalStorageSize: (state, action) => {
            state.local.size = action.payload;
        },
    },
});
export const broswerStorageActions = browserStorageSlice.actions;
export const { cacheGalactusUserProfile, cacheActiveData, crossdomainStatus, sessionPageViewSaved, sessionActionSaved, sessionAddInteraction, updateCrossdomainSize, updateCrossdomainPages, updateCrossdomainActions, updateCrossdomainInteractions, updateLocalHistory, updateLocalStorageSize, } = browserStorageSlice.actions;
export default browserStorageSlice.reducer;
