import { isGlobalDebugMode, isLocalHost } from './env';
function dummyLog() {
    // noop;
}
const BACKUP_ORIGIN_TIME = Date.now(); // fallback for timeorigin
function getTimeDelta() {
    var _a, _b;
    const currentTimeStamp = Date.now();
    const originTime = (_b = (_a = window.performance) === null || _a === void 0 ? void 0 : _a.timeOrigin) !== null && _b !== void 0 ? _b : BACKUP_ORIGIN_TIME;
    const delta = currentTimeStamp - originTime;
    const secondsResult = delta / 1000;
    return secondsResult.toFixed(4);
}
function typeToFunction(type) {
    if (type === 'error') {
        return console.error;
    }
    if (type === 'debug') {
        if (isGlobalDebugMode() || isLocalHost()) {
            return console.debug;
        }
        return dummyLog;
    }
    return console.log;
}
const consoleLog = (type, prefix, styles, message, ...data) => {
    const func = typeToFunction(type);
    const fullMessage = `%c${prefix} | ${getTimeDelta()}s | ${message}`;
    if (data.length) {
        func(fullMessage, styles, ...data);
    }
    else {
        func(fullMessage, styles);
    }
};
const consoleLoggerFactory = (prefix, type, styles = []) => {
    return (message, ...data) => consoleLog(type, prefix, styles.join(';'), message, ...data);
};
export default consoleLoggerFactory;
