import uniqBy from 'lodash/uniqBy';
import { getSerializableClient, isClientEqual } from '../SilverSurferClient';
/** Make sure list unique and sorted in reverse chronological order */
export function clearClientActionList(list) {
    return uniqBy(list, (ca) => ca.action.time).sort((a, b) => b.action.time - a.action.time);
}
// definition
export function buildClientAction(client, name, type, payload) {
    return {
        client: getSerializableClient(client),
        action: {
            time: Date.now(),
            name,
            type,
            payload,
        },
    };
}
export function filterActions(actions, client, type) {
    let result = actions.filter((full) => isClientEqual(full.client, client)).map((full) => full.action);
    // filter by type (if present)
    if (typeof type === 'string' && type.length > 0) {
        result = result.filter((action) => action.type === type);
    }
    return result;
}
