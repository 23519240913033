import { Communicator, setupPostQuecast } from '@wikia/post-quecast';
class CommunicationService {
    constructor() {
        this.history = new Map();
        setupPostQuecast();
        this.communicator = new Communicator();
    }
    dispatch(action) {
        this.communicator.dispatch(Object.assign(Object.assign({}, action), { __global: true }));
    }
    on(actionType, callback) {
        this.communicator.addListener((action) => this.run(action, actionType, callback));
    }
    once(actionType, callback) {
        this.communicator.addListener((action) => this.runOnce(action, actionType, callback));
    }
    run(action, actionType, callback) {
        if (this.ofType(action, actionType)) {
            callback(action);
        }
    }
    runOnce(action, actionType, callback) {
        if (!this.ofType(action, actionType)) {
            return;
        }
        if (this.history.has(actionType)) {
            return callback(action);
        }
        this.history.set(actionType, true);
        callback(action);
    }
    ofType(action, actionType) {
        return action.type === actionType;
    }
}
let communicationService = null;
export function getCommunicationService() {
    if (communicationService === null) {
        communicationService = new CommunicationService();
    }
    return communicationService;
}
/**
 * @deprecated
 *
 * For testing purposes only.
 */
export function recreateCommunicationService() {
    communicationService = new CommunicationService();
    return communicationService;
}
