// Lightweight Redux store observer
// based on https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
// with isEqual fix
import isEqual from 'lodash/isEqual';
/**
 * Observe changes in Redux state:
 * S - type of store
 * R - return of the selector function
 *
 * @param store Redux store
 * @param select Selector function
 * @param onChange callback
 * @returns unsubscribe
 */
export default function observeStore(store, select, onChange) {
    let currentState;
    function handleChange() {
        const nextState = select(store.getState());
        if (!isEqual(nextState, currentState)) {
            currentState = nextState;
            onChange(currentState);
        }
    }
    const unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
}
