// IMPORTANT -- Whenever adding to this list you need to ensure your event name is caught by GTM
export const GTM_REGISTERED_EVENT = {
    SilverSurfer: 'silversurfer',
    PathfinderExperiments: 'pf-experiments',
    ClientSide: 'browser-client-',
    Custom: 'custom-',
};
export const GTM_REGISTERED_VALUES = Object.values(GTM_REGISTERED_EVENT);
export default function isRegisteredGTMEvent(eventName) {
    return GTM_REGISTERED_VALUES.some((eventSubString) => {
        return eventName.includes(eventSubString);
    });
}
