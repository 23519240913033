export var ROOTS;
(function (ROOTS) {
    ROOTS["INIT"] = "roots-initial";
    ROOTS["LOADED"] = "roots-loaded";
})(ROOTS || (ROOTS = {}));
export var SS_EVENTS;
(function (SS_EVENTS) {
    SS_EVENTS["SS_PRE_BOOTSTRAP"] = "ss_pre_bootstrap";
    SS_EVENTS["SS_SDK_START"] = "ss_sdk_start";
    SS_EVENTS["SS_SDK_LOADED"] = "ss_sdk_loaded";
    SS_EVENTS["SS_SDK_READY"] = "ss_sdk_ready";
    SS_EVENTS["SS_CROSS_DOMAIN_READY"] = "ss_cross_domain_ready";
    SS_EVENTS["SS_CROSS_DOMAIN_REGISTER_START"] = "ss_cross_domain_register_start";
    SS_EVENTS["SS_CROSS_DOMAIN_REGISTER_ALL_PAGES_DONE"] = "ss_cross_domain_register_all_pages_done";
})(SS_EVENTS || (SS_EVENTS = {}));
export var PF_EVENTS;
(function (PF_EVENTS) {
    PF_EVENTS["PF_START"] = "pf_start";
    PF_EVENTS["PF_LOADED"] = "pf_loaded";
    PF_EVENTS["PF_READY"] = "pf_ready";
})(PF_EVENTS || (PF_EVENTS = {}));
