/**
 * Check if object's empty
 */
export function isEmptyObject(o) {
    return Object.keys(o || {}).length === 0;
}
/**
 * Sum array of objects
 */
export function sumNumericalObjects(objects) {
    const result = {};
    objects.forEach((basket) => {
        for (const [key, value] of Object.entries(basket)) {
            if (result[key]) {
                result[key] += value;
            }
            else {
                result[key] = value;
            }
        }
    });
    return result;
}
/**
 * Calculate number of entities in all objects in an array
 */
export function countNumberOfEntities(objects) {
    try {
        return objects.reduce((sum, current) => sum + Object.keys(current).length, 0);
    }
    catch (e) {
        return 0;
    }
}
