import { __awaiter } from "tslib";
import * as localStorageDriver from './drivers/localStorage';
import * as indexedDBDriver from './drivers/indexedDB';
import log from '../utils/log';
export var StorageType;
(function (StorageType) {
    StorageType["IndexedDB"] = "indexeddb";
    StorageType["LocalStorage"] = "localstorage";
})(StorageType || (StorageType = {}));
// Limit size of the page view table on local domain
export const STORAGE_PAGEVIEW_LIMIT = {
    [StorageType.IndexedDB]: 200,
    [StorageType.LocalStorage]: 100,
};
// Limit size of the actions table on local domain
export const STORAGE_ACTIONS_LIMIT = {
    [StorageType.IndexedDB]: 500,
    [StorageType.LocalStorage]: 200,
};
// Limit size of the localstorage on cross domain (to limit the size of the data we send from the server and use on the client in fallback scenario)
export const LOCALSTORAGE_MAX_ENTRIES = Math.max(STORAGE_ACTIONS_LIMIT[StorageType.LocalStorage], STORAGE_PAGEVIEW_LIMIT[StorageType.LocalStorage]);
function hasLocalStorage() {
    try {
        return !!(window === null || window === void 0 ? void 0 : window.localStorage);
    }
    catch (e) {
        return false;
    }
}
function hasIndexedDB() {
    try {
        return !!(window === null || window === void 0 ? void 0 : window.indexedDB);
    }
    catch (e) {
        return false;
    }
}
export function saveGalactusUserProfile(beaconId, userProfile) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB()
            ? yield indexedDBDriver.saveGalactusUserProfile(beaconId, userProfile)
            : false;
        const savedToLocalStorage = hasLocalStorage()
            ? yield localStorageDriver.saveGalactusUserProfile(beaconId, userProfile)
            : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to cache Galactus response', beaconId, userProfile);
        }
        return saved;
    });
}
export function loadGalactusUserProfile(beaconId) {
    return __awaiter(this, void 0, void 0, function* () {
        // try to get data from indexedDB
        if (hasIndexedDB()) {
            return yield indexedDBDriver.loadGalactusUserProfile(beaconId);
        }
        // fallback to localStorage
        if (hasLocalStorage()) {
            return yield localStorageDriver.loadGalactusUserProfile(beaconId);
        }
        return null;
    });
}
export function appendPageTrack(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.appendPageTrack(entry) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.appendPageTrack(entry) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to track page', entry);
        }
        return saved;
    });
}
export function setAllPageTracks(entries) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.setAllPageTracks(entries) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.setAllPageTracks(entries) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to update local pages');
        }
        else {
            log.debug('Local pages updated from cross domain');
        }
        return saved;
    });
}
export function getAllPageTracks() {
    return __awaiter(this, void 0, void 0, function* () {
        // try to get data from indexedDB
        if (hasIndexedDB()) {
            return yield indexedDBDriver.getAllPageTracks();
        }
        // fallback to localStorage
        if (hasLocalStorage()) {
            return yield localStorageDriver.getAllPageTracks();
        }
        return [];
    });
}
export function addAction(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.addAction(entry) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.addAction(entry) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to save action', entry);
        }
        return saved;
    });
}
export function setActions(actions) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.setActions(actions) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.setActions(actions) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to update local actions');
        }
        else {
            log.debug('Local actions updated from cross domain');
        }
        return saved;
    });
}
export function getAllActions() {
    return __awaiter(this, void 0, void 0, function* () {
        // try to get data from indexedDB
        if (hasIndexedDB()) {
            return yield indexedDBDriver.getAllActions();
        }
        // fallback to localStorage
        if (hasLocalStorage()) {
            return yield localStorageDriver.getAllActions();
        }
        return [];
    });
}
export function addInteraction(type) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.addInteraction(type) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.addInteraction(type) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to save interaction', type);
        }
        return saved;
    });
}
export function setInteractions(interactions) {
    return __awaiter(this, void 0, void 0, function* () {
        const savedToIndexedDB = hasIndexedDB() ? yield indexedDBDriver.setInteractions(interactions) : false;
        const savedToLocalStorage = hasLocalStorage() ? yield localStorageDriver.setInteractions(interactions) : false;
        const saved = savedToIndexedDB || savedToLocalStorage;
        if (!saved) {
            log.error('Failed to update local interactions');
        }
        else {
            log.debug('Local interactions updated from cross domain');
        }
        return saved;
    });
}
export function getInteractions() {
    return __awaiter(this, void 0, void 0, function* () {
        // try to get data from indexedDB
        if (hasIndexedDB()) {
            return yield indexedDBDriver.getInteractions();
        }
        // fallback to localStorage
        if (hasLocalStorage()) {
            return yield localStorageDriver.getInteractions();
        }
        return {};
    });
}
export function getAllStorageSize() {
    return __awaiter(this, void 0, void 0, function* () {
        let localStorageSize = -1;
        let indexedDBSize = -1;
        if (hasIndexedDB()) {
            try {
                localStorageSize = yield localStorageDriver.getDatabaseSize();
            }
            catch (e) {
                log.debug('Error while getting localStorage size', e);
            }
        }
        if (hasLocalStorage()) {
            try {
                indexedDBSize = yield indexedDBDriver.getDatabaseSize();
            }
            catch (e) {
                log.debug('Error while getting indexedDB size', e);
            }
        }
        return { localStorageSize, indexedDBSize };
    });
}
