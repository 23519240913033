import { __awaiter } from "tslib";
import { callFandomService } from '../utils/callFandomService';
import log from '../utils/log';
import { fillEmptyFields } from '../models/Galactus';
import waitForConsent from 'utils/waitForConsent';
import { track } from 'fandom-frontend-ready/tracking/dataLayer';
import botCheck from 'utils/botCheck';
// example curl --request GET "http://dev.sjc-dev.k8s.wikia.net/galactus-user-profile/profiles/eh_68UYTxhg" \
// --header 'X-Wikia-Internal-Request: 1' --header "Cookie: wikia_beacon_id=eh_68UYTxhg"
export function getUserProfileFromApi(beacon) {
    return __awaiter(this, void 0, void 0, function* () {
        // do not call galactus when bots are here
        if (botCheck()) {
            return null;
        }
        // ensure context is given, but not throw any errors
        try {
            yield waitForConsent();
        }
        catch (err) {
            log.debug('Consent not given, cannot contact Galactus');
            return null;
        }
        // contact galactus
        try {
            const response = yield callFandomService(`galactus-user-profile/profiles/${beacon}`);
            if (response.status === 200) {
                const profile = (yield response.json());
                const galactusProfile = fillEmptyFields(profile);
                log.debug('Galactus API response', galactusProfile);
                // Push a custom data layer event
                track({
                    event: 'custom-galactus-api-response-full',
                    category: 'custom-galactus-api-response-full',
                    label: 'custom-galactus-api-response-full',
                    galactusResponse: profile,
                    skipValidation: true,
                });
                return galactusProfile;
            }
        }
        catch (e) {
            log.error('Failed to fetch profile', e);
        }
        return null;
    });
}
