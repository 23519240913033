var _a, _b, _c;
import { isLocalHost as isLocalHostFandomFE } from '../fandom-frontend-ready/env';
/**
 * Lightweight env file, optimized towards tree shaking
 * Rules:
 * - `const`s only, as static as possible
 * - do not export objects
 */
function isBrowser() {
    return typeof window !== 'undefined';
}
export function isFandomCom() {
    if (isBrowser() && window.location && window.location.hostname) {
        return window.location.hostname.includes('fandom.com');
    }
    // doesn't work on SSR
    return false;
}
export function getServicesBaseURL() {
    return isFandomCom()
        ? 'https://services.fandom.com/'
        : 'https://services.fandom-dev.' + (location.hostname.match(/(?!\.)(pl|us)$/) || ['us'])[0] + '/';
}
export const QUERY_PARAM_NAMES = {
    DEBUG: 'ss_debug',
    BRANCH: 'ss_branch_deploy',
    CANARY: 'ss_canary',
    ENABLED_PATHFINDER_MODULE: 'ss_pathfinder_enable',
    FORCED_PATHFINDER: 'ss_pathfinder_forced',
    FORCED_BUCKETING: 'ss_pathfinder_force_bucket',
};
/**
 * @returns `true` on localhost
 */
export const isLocalHost = isLocalHostFandomFE;
/**
 * @returns `true` for fandom dev domains
 */
export const isDevDomain = () => /fandom-dev\.(pl|us)$/.test(location.hostname);
/**
 * @returns `true` for fandom-dev.pl domain
 */
export const isPLDevDomain = () => /fandom-dev\.pl/.test(location.hostname);
// @ts-expect-error Webpack ensures `COMMIT_HASH` variable is defined
export const SS_VERSION = ((_a = COMMIT_HASH) === null || _a === void 0 ? void 0 : _a.replace(/\n/g, '')) || 'local';
// @ts-expect-error Webpack ensures `NODE_ENV` variable is defined
export const IS_PRODUCTION = (_b = NODE_ENV === 'production') !== null && _b !== void 0 ? _b : false;
// @ts-expect-error Webpack ensures `NODE_ENV` variable is defined
export const IS_NOT_PRODUCTION = (_c = NODE_ENV !== 'production') !== null && _c !== void 0 ? _c : false;
/**
 * Helper to check if query param is present
 * @returns boolean `true` query param is present
 */
export const hasQueryParam = (param) => window.location.search.includes(param);
/**
 * Helper for global debug mode: `ss_debug=true`
 * @returns `true` if debug mode is enabled
 */
export const isGlobalDebugMode = () => hasQueryParam(`${QUERY_PARAM_NAMES.DEBUG}=true`);
/**
 * Helper for checking if we have any active query param
 * @returns `true` if any reserved query param is in the query string
 */
export function hasAnyTestingQueryParam() {
    const possibleKeys = Object.values(QUERY_PARAM_NAMES);
    for (let i = 0; i < possibleKeys.length; i++) {
        if (hasQueryParam(possibleKeys[i])) {
            return true;
        }
    }
    return false;
}
