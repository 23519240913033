import { __awaiter } from "tslib";
import log from 'sdk/utils/log';
import store from 'sdk/store/store';
import { cacheActiveData } from 'sdk/store/browserStorageSlice';
import { getActiveDataFromAPI } from 'sdk/api/activeDataManagementSystem';
import { getActiveDataFromCache } from 'sdk/store/selectors';
import isEmpty from 'lodash/isEmpty';
const ACTIVE_DATA_FETCH_INTERVAL = 60 * 1000; // 1 minute
let lastUpdated = 0;
export function getActiveData() {
    return __awaiter(this, void 0, void 0, function* () {
        const cached = getActiveDataFromCache(store.getState());
        // if we have cached active data, and we updated it less than minute ago
        if (!isEmpty(cached) && Date.now() - lastUpdated < ACTIVE_DATA_FETCH_INTERVAL) {
            return cached;
        }
        const activeData = yield getActiveDataFromAPI();
        log.debug('getActiveData: read from ADMS', activeData);
        lastUpdated = Date.now();
        store.dispatch(cacheActiveData({ activeData }));
        return activeData;
    });
}
