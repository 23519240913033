import { cleanPageContextList } from '@fandom/context';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';
import { sumNumericalObjects } from '../../utils/objectHelper';
import { clearClientActionList } from '../models/Action';
import { Status } from '../storage/crossdomain/types';
import { AppState } from './appSlice';
// reusable selectors
// used in App logic
export const isSilverSurferReady = (state) => state.App.state === AppState.Ready;
export const isSilverSurferStarting = (state) => state.App.state === AppState.Starting;
export const isSilverSurferDelayed = (state) => state.App.state === AppState.Delayed;
export const isSilverSurferNew = (state) => state.App.state === AppState.New;
export const getAllRegisteredClients = (state) => state.App.clients;
export const findClientByToken = (token) => createSelector(getAllRegisteredClients, (clients) => clients.find((client) => client.token === token));
// used in config
export const getConfig = (state) => state.App.config;
export const configCanSendDebugLog = (state) => state.App.config.sendDebugLog;
export const configCanSendGalactusRequest = (state) => state.App.config.sendGalactusRequest;
export const configCanSendTracking = (state) => state.App.config.sendTracking;
export const configHasCrossdomainDisabled = (state) => state.App.config.disableCrossdomain;
export const configHasManualPageview = (state) => state.App.config.manualPageview;
// used in APIs
export const getUserContext = (state) => state.Context.user;
export const getPageContext = (state) => state.Context.page;
export const isAdBlockDetected = (state) => state.Context.adBlockDetected;
export const getAdSlots = (state) => state.Context.adSlots;
export const getLocalStatus = (state) => state.BrowserStorage.local.status;
export const getCrossdomainStatus = (state) => state.BrowserStorage.crossdomain.status;
export const getCrossdomainStatusMessage = (state) => state.BrowserStorage.crossdomain.statusErrorMessage;
export const isCrossdomainIntialized = createSelector(getCrossdomainStatus, (status) => status !== 'initializing');
export const isCrossdomainReady = createSelector(getCrossdomainStatus, (status) => status === Status.Ready);
export const isLocalReady = createSelector(getLocalStatus, (status) => status === Status.Ready);
export const isStorageReady = createSelector(isCrossdomainReady, isLocalReady, (crossdomainReady, localReady) => crossdomainReady || localReady);
export const getLocalStorageSize = (state) => state.BrowserStorage.local.size;
export const getCrossdomainStorageSize = (state) => state.BrowserStorage.crossdomain.size;
export const isSessionPageViewSaved = (state) => state.BrowserStorage.session.pages.length > 0;
export const getHistoryPages = (state) => state.BrowserStorage.history.pages;
export const getSessionPages = (state) => state.BrowserStorage.session.pages;
export const getPages = createSelector(getPageContext, getSessionPages, getHistoryPages, (plc, session, history) => cleanPageContextList([plc].concat(session).concat(history).filter(Boolean)));
export const getHistoryActions = (state) => state.BrowserStorage.history.actions;
export const getSessionActions = (state) => state.BrowserStorage.session.actions;
export const getActions = createSelector(getSessionActions, getHistoryActions, (session, history) => clearClientActionList(session.concat(history).filter(Boolean)));
export const getHistoryInteractions = (state) => state.BrowserStorage.history.interactions;
export const getSessionInteractions = (state) => state.BrowserStorage.session.interactions;
export const getInteractions = createSelector(getSessionInteractions, getHistoryInteractions, (session, history) => sumNumericalObjects([session, history]));
export const getInteractionsArray = createSelector(getSessionInteractions, getHistoryInteractions, (session, history) => {
    const sessionInteractions = Object.keys(session);
    const historyInteractions = Object.keys(history);
    const interactions = [].concat(sessionInteractions, historyInteractions).filter(Boolean);
    return uniq(interactions);
});
export const getVisitedProducts = createSelector([getPages], (visits) => {
    const allProductsVisits = {};
    // grab all visits from state
    visits.forEach((visit) => {
        if (visit.isValid) {
            const { product, productId } = visit;
            // check if we already have a visit for this product
            const visitsToProduct = allProductsVisits[product] || {};
            // increase counter for this product
            visitsToProduct[productId] = (visitsToProduct[productId] || 0) + 1;
            // save procuct visits
            allProductsVisits[product] = visitsToProduct;
        }
    });
    // return all products visits
    return allProductsVisits;
});
export const isCrossdomainPagesFinished = (state) => state.BrowserStorage.crossdomain.pagesFinished;
export const isCrossdomainActionsFinished = (state) => state.BrowserStorage.crossdomain.actionsFinished;
export const isCrossdomainInteractionsFinished = (state) => state.BrowserStorage.crossdomain.interactionsFinished;
export const isCrossdomainFinished = createSelector(isCrossdomainPagesFinished, isCrossdomainActionsFinished, isCrossdomainInteractionsFinished, (a, b, c) => a && b && c);
export const isCrossdomainDisabled = (state) => state.App.config.disableCrossdomain || state.BrowserStorage.crossdomain.status === Status.Off;
// used in service caches
export const getGalactusUserProfileFromCache = (profileId) => (state) => { var _a; return (_a = state.BrowserStorage.serviceCache.galactusUserProfile) === null || _a === void 0 ? void 0 : _a[profileId]; };
export const isGalactusUserProfileCached = (profileId) => (state) => { var _a; return !!((_a = state.BrowserStorage.serviceCache.galactusUserProfile) === null || _a === void 0 ? void 0 : _a[profileId]); };
export const getActiveDataFromCache = (state) => state.BrowserStorage.serviceCache.activeData;
// more complex
export const canSilverSurferBeReady = createSelector(isSilverSurferStarting, isLocalReady, getPageContext, (ssIsStarting, localReady, currentPage) => ssIsStarting && localReady && !!currentPage);
