export var ActionType;
(function (ActionType) {
    ActionType["ADD"] = "add";
    ActionType["CLICK"] = "click";
    ActionType["CLOSE"] = "close";
    ActionType["CONFIRM"] = "confirm";
    ActionType["CREATE"] = "create";
    ActionType["DISABLE"] = "disable";
    ActionType["ENABLE"] = "enable";
    ActionType["ERROR"] = "error";
    ActionType["HOVER"] = "hover";
    ActionType["IMPRESSION"] = "impression";
    ActionType["IDENTITY"] = "identity";
    ActionType["KEYPRESS"] = "keypress";
    ActionType["NO_IMPRESSION"] = "no-impression";
    ActionType["OPEN"] = "open";
    ActionType["PLAY_VIDEO"] = "play-video";
    ActionType["QUIZ"] = "quiz";
    ActionType["REACTION"] = "reaction";
    ActionType["REMOVE"] = "remove";
    ActionType["SELECT"] = "select";
    ActionType["SHARE"] = "share";
    ActionType["SUBMIT"] = "submit";
    ActionType["SUCCESS"] = "success";
    ActionType["SWIPE"] = "swipe";
    ActionType["VIEW"] = "view";
    ActionType["REACTION_COUNT"] = "reaction-count";
    ActionType["HEALTH"] = "health";
    ActionType["HIGHLIGHT"] = "highlight";
    ActionType["MYFANDOM"] = "myfandom";
    ActionType["CALENDAR_EVENTS"] = "calendarevents";
    ActionType["COLLECTIONITEM"] = "collectionitem";
})(ActionType || (ActionType = {}));
