import { __awaiter, __rest } from "tslib";
import takeRight from 'lodash/takeRight';
import { openDB } from 'idb';
import omit from 'lodash/omit';
import { GALACTUS_API_VERSION } from '../../models/Galactus';
import log from '../../utils/log';
import triggerMetric from 'fandom-frontend-ready/alerts-metrics/metric';
import { STORAGE_ACTIONS_LIMIT, STORAGE_PAGEVIEW_LIMIT, StorageType } from '../storage';
const DATABASE = 'silver-surfer';
const VERSION = 6;
const PAGE_VIEW_TABLE = 'page-view-table';
const ACTIONS_TABLE = 'actions-table';
const INTERACTIONS_TABLE = 'interactions-table';
const INTERACTIONS_TABLE_KEY = 'current'; // only key we use for storing interactions
const GALACTUS_FALLBACK_USER_PROFILE_TABLE = 'galactus-fallback-user-profile-table';
const ACTIVE_DATA_TABLE = 'active-data-table';
// let's flag if the indexedDB has failed, so we won't pollute the console
let indexedDBFailed = false;
export function getDB() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!indexedDBFailed) {
            try {
                return yield openDB(DATABASE, VERSION, {
                    upgrade(db, _oldVersion, _newVersion, _transaction) {
                        if (!db.objectStoreNames.contains(PAGE_VIEW_TABLE)) {
                            db.createObjectStore(PAGE_VIEW_TABLE);
                        }
                        if (!db.objectStoreNames.contains(GALACTUS_FALLBACK_USER_PROFILE_TABLE)) {
                            db.createObjectStore(GALACTUS_FALLBACK_USER_PROFILE_TABLE);
                        }
                        if (!db.objectStoreNames.contains(ACTIONS_TABLE)) {
                            db.createObjectStore(ACTIONS_TABLE, {
                                autoIncrement: true,
                            });
                        }
                        if (!db.objectStoreNames.contains(INTERACTIONS_TABLE)) {
                            db.createObjectStore(INTERACTIONS_TABLE);
                        }
                        // This was removed, but it's still part of the schema
                        if (!db.objectStoreNames.contains(ACTIVE_DATA_TABLE)) {
                            db.createObjectStore(ACTIVE_DATA_TABLE);
                        }
                    },
                    blocked() {
                        // …
                    },
                    blocking() {
                        // …
                    },
                    terminated() {
                        // …
                    },
                });
            }
            catch (e) {
                log.error('Cannot access indexedDB:', e);
                triggerMetric('cannot-access-indexeddb', 1, 1);
                indexedDBFailed = true;
            }
        }
    });
}
const getGalactusUserProfileKey = (beaconId) => `user-profile-${beaconId}`;
export function loadGalactusUserProfile(beaconId) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const userProfileObject = yield (db === null || db === void 0 ? void 0 : db.get(GALACTUS_FALLBACK_USER_PROFILE_TABLE, getGalactusUserProfileKey(beaconId)));
        // if we fetched the object and the version is the same
        if (userProfileObject && userProfileObject.apiVersion === GALACTUS_API_VERSION) {
            return omit(userProfileObject, 'apiVersion');
        }
        return null;
    });
}
export function saveGalactusUserProfile(beaconId, userProfile) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const key = getGalactusUserProfileKey(beaconId);
        const userProfileObject = Object.assign(Object.assign({}, userProfile), { apiVersion: GALACTUS_API_VERSION });
        const newKey = yield (db === null || db === void 0 ? void 0 : db.put(GALACTUS_FALLBACK_USER_PROFILE_TABLE, userProfileObject, key));
        return newKey === key;
    });
}
export function getAllPageTracks() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const keys = (_a = (yield (db === null || db === void 0 ? void 0 : db.getAllKeys(PAGE_VIEW_TABLE)))) !== null && _a !== void 0 ? _a : [];
        const values = yield (db === null || db === void 0 ? void 0 : db.getAll(PAGE_VIEW_TABLE));
        // we retrieved key and values, let's rebuild the ID
        return keys.map((key, index) => (Object.assign({ id: key }, values[index])));
    });
}
export function setAllPageTracks(entries) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        // make sure we save the data - use better durability
        const tx = db === null || db === void 0 ? void 0 : db.transaction(PAGE_VIEW_TABLE, 'readwrite', { durability: 'strict' });
        if (!(tx === null || tx === void 0 ? void 0 : tx.store)) {
            return false;
        }
        // remove existing pages
        yield tx.store.clear();
        // add pages
        let result = true;
        takeRight(entries, STORAGE_PAGEVIEW_LIMIT[StorageType.IndexedDB]).forEach((entry) => __awaiter(this, void 0, void 0, function* () {
            const { id } = entry, pageViewObject = __rest(entry, ["id"]);
            const key = yield tx.store.put(pageViewObject, id);
            result && (result = key === id);
        }));
        // wait for transaction to end
        yield tx.done;
        return result;
    });
}
export function appendPageTrack(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const { id } = entry, pageViewObject = __rest(entry, ["id"]);
        const key = yield (db === null || db === void 0 ? void 0 : db.put(PAGE_VIEW_TABLE, pageViewObject, id));
        return key === id;
    });
}
export function addInteraction(type) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const interactions = yield getInteractions();
        const count = (_a = interactions[type]) !== null && _a !== void 0 ? _a : 0;
        interactions[type] = count + 1;
        return setInteractions(interactions);
    });
}
export function setInteractions(interactions) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const newKey = yield (db === null || db === void 0 ? void 0 : db.put(INTERACTIONS_TABLE, interactions, INTERACTIONS_TABLE_KEY));
        return newKey === INTERACTIONS_TABLE_KEY;
    });
}
export function getInteractions() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const interactions = (_a = (yield (db === null || db === void 0 ? void 0 : db.get(INTERACTIONS_TABLE, INTERACTIONS_TABLE_KEY)))) !== null && _a !== void 0 ? _a : {};
        return interactions;
    });
}
export function getAllActions() {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        return yield (db === null || db === void 0 ? void 0 : db.getAll(ACTIONS_TABLE));
    });
}
export function addAction(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        const key = yield (db === null || db === void 0 ? void 0 : db.put(ACTIONS_TABLE, entry));
        return !!key;
    });
}
export function setActions(actions) {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        // make sure we save the data - use better durability
        const tx = db === null || db === void 0 ? void 0 : db.transaction(ACTIONS_TABLE, 'readwrite', { durability: 'strict' });
        if (!(tx === null || tx === void 0 ? void 0 : tx.store)) {
            return false;
        }
        // remove existing actions
        yield tx.store.clear();
        // add actions
        let result = true;
        takeRight(actions, STORAGE_ACTIONS_LIMIT[StorageType.IndexedDB]).forEach((action) => __awaiter(this, void 0, void 0, function* () {
            const key = yield tx.store.put(action);
            result && (result = !!key);
        }));
        // wait for transaction to end
        yield tx.done;
        return result;
    });
}
// this should never be called in production
export function getDatabaseSize() {
    return __awaiter(this, void 0, void 0, function* () {
        const db = yield getDB();
        let stringSizes = 0;
        let cursor1 = yield (db === null || db === void 0 ? void 0 : db.transaction(PAGE_VIEW_TABLE).store.openCursor());
        while (cursor1) {
            stringSizes += JSON.stringify(cursor1.value).length;
            cursor1 = yield cursor1.continue();
        }
        let cursor2 = yield (db === null || db === void 0 ? void 0 : db.transaction(ACTIONS_TABLE).store.openCursor());
        while (cursor2) {
            stringSizes += JSON.stringify(cursor2.value).length;
            cursor2 = yield cursor2.continue();
        }
        // each character is max 4 bytes in JavaScript =
        // https://stackoverflow.com/questions/2219526/how-many-bytes-in-a-javascript-string#:~:text=As%20CMS%20pointed%20out%20in,16%20bits%20(2%20bytes).
        return stringSizes * 4;
    });
}
// returns a % of the usage
export function getUsedStoragePercent() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!navigator || !navigator.storage || !navigator.storage.estimate) {
            return 0;
        }
        const estimate = yield navigator.storage.estimate();
        return (estimate.usage / estimate.quota) * 100;
    });
}
