import { Product, getPageContextFromWindow, PageContext } from '@fandom/context';
import get from 'lodash/get';
import random from 'lodash/random';
import { hasQueryParam, isGlobalDebugMode, isLocalHost } from '../../utils/env';
import { CROSS_DOMAIN_HOST } from '../storage/consts';
import log from './log';
import botCheck from 'utils/botCheck';
/*
This is readonly config (that can be initialized by writing to `window._ss` object before SilverSurfer's loaded)

```js
window._ss=window._ss||{};
window._ss.sendDebugLogRate = x;
```
*/
/**
 * List of products and productIds where sending tracking is enabled.
 */
const TRACKING_ENABLED_ON = {
    [Product.UNKNOWN]: [],
    [Product.MEDIAWIKI]: [
        '2350603',
        '174',
        '38969',
        '4396',
        '159',
        '130814',
        '2180',
        '105',
        '2237', // dc
    ],
    [Product.DISCUSSIONS]: [],
    [Product.TRIVIA]: [],
    [Product.FANHUB]: [],
    [Product.VIDEO]: [],
    [Product.F2]: [],
};
function checkChance(percent) {
    if (percent < 1.0) {
        return random(0, 100) <= percent * 100;
    }
    return true;
}
function getSendDebugLog() {
    return checkChance(get(window, '_ss.sendDebugLogRate', 0.01));
}
function getSendGalactusRequest() {
    return checkChance(get(window, '_ss.sendGalactusRequestRate', 1));
}
function getSendTracking() {
    const plc = getPageContextFromWindow();
    const enabledOnProductIds = TRACKING_ENABLED_ON[plc.product];
    const isTrackingEnabled = enabledOnProductIds === null || enabledOnProductIds === void 0 ? void 0 : enabledOnProductIds.includes(plc.productId);
    return checkChance(get(window, '_ss.sendTrackingRate', isTrackingEnabled ? 1 : 0));
}
function getDisableCrossdomain() {
    var _a;
    // disable crossdomain when bots are detected
    if (botCheck()) {
        return true;
    }
    // disable cross domain if it's not needed
    if (((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.host) === CROSS_DOMAIN_HOST) {
        log.debug('Cross domain is not needed for fandom.com');
        return true;
    }
    return get(window, '_ss.disableCrossdomain', false);
}
function getManualPageview() {
    const manualPageviewParam = get(window, '_ss.manualPageview', false);
    const pageContext = PageContext.fromWindow();
    let assumeLazyLoadedContext = false;
    if (!pageContext.isValid) {
        log.debug('Page context is not valid! Assuming lazy load (need manual PV trigger)', pageContext);
        assumeLazyLoadedContext = true;
    }
    return manualPageviewParam || assumeLazyLoadedContext;
}
export function getInitialConfig() {
    return {
        sendDebugLog: isGlobalDebugMode() || getSendDebugLog(),
        sendGalactusRequest: isGlobalDebugMode() || isLocalHost() || getSendGalactusRequest(),
        sendTracking: getSendTracking(),
        disableCrossdomain: hasQueryParam('ss_no_crossdomain=true') || getDisableCrossdomain(),
        manualPageview: (isLocalHost() && hasQueryParam('ss_no_pageview=true')) || getManualPageview(),
    };
}
