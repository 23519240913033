// Used by two factories below
function logErrorEvent(client, clientVersion, name, data = {}) {
    console.error('logErrorEvent', { client, clientVersion, name, data });
}
function logDebugEvent(client, clientVersion, name, data = {}) {
    console.debug('logDebugEvent', { client, clientVersion, name, data });
}
const responseToEventLogData = ({ ok, redirected, status, statusText, type, url }) => ({
    ok,
    redirected,
    status,
    statusText,
    type,
    url,
});
// exports
export const logDebugEventFactory = (client, clientVersion) => {
    return (name, data = {}) => logDebugEvent(client, clientVersion, name, data);
};
export const logErrorEventFactory = (client, clientVersion) => {
    return (name, data = {}) => logErrorEvent(client, clientVersion, name, data);
};
export const logResponseErrorFactory = (client, clientVersion) => {
    return (name, response) => logErrorEvent(client, clientVersion, name, responseToEventLogData(response));
};
