import trackerFactoryDataLayer from 'fandom-frontend-ready/tracking';
import { isDebug } from '../env';
import { consistentRandomNumber } from '../utils/getConsistentRandomNumber';
const tracker = trackerFactoryDataLayer({ event: 'browser-client-timings' });
const SAMPLE_LEVEL = 0.005;
export function isInSample() {
    // TODO change this to a more generic variable name for widespread usage
    if (isDebug()) {
        return true;
    }
    return consistentRandomNumber() <= SAMPLE_LEVEL;
}
export function trackSingleTime(eventName, time) {
    if (!isInSample()) {
        return;
    }
    tracker({
        category: `timings-event-${eventName}`,
        label: time.toString(),
        action: 'timed',
        value: time,
    });
}
export function trackDelta(eventOne, eventTwo, delta) {
    if (!isInSample()) {
        return;
    }
    tracker({
        category: `timings-event-${eventOne}-to-${eventTwo}`,
        label: delta.toString(),
        action: 'timed',
        value: delta,
    });
}
