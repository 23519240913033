import { __awaiter } from "tslib";
import { getCommunicationService } from 'utils/communicationService';
import { AD_ENGINE_CONSENT_READY } from 'utils/communicationService/adEngineTypes';
function waitForConsent() {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof (window === null || window === void 0 ? void 0 : window.__f_optin) === 'boolean') {
            return Promise.resolve(window.__f_optin);
        }
        const communicationService = getCommunicationService();
        return new Promise((res, rej) => {
            communicationService.once(AD_ENGINE_CONSENT_READY, ({ geoRequiresConsent, geoRequiresSignal, gdprConsent, ccpaSignal }) => {
                if ((geoRequiresConsent && gdprConsent) ||
                    (geoRequiresSignal && !ccpaSignal) ||
                    (!geoRequiresSignal && !geoRequiresConsent)) {
                    res(true);
                }
                else {
                    rej(false);
                }
            });
        });
    });
}
export default waitForConsent;
