// wait for something to happen
export default function waitForEvent(options) {
    let intervals = 0;
    const onResolve = typeof (options === null || options === void 0 ? void 0 : options.onResolve) === 'function' ? options.onResolve : () => undefined;
    const onReject = typeof (options === null || options === void 0 ? void 0 : options.onReject) === 'function' ? options.onReject : () => undefined;
    return () => new Promise((resolve, reject) => {
        const intervalID = setInterval(() => {
            if (options.eventCheck()) {
                clearInterval(intervalID);
                resolve(onResolve());
            }
            if (options.intervalTime * intervals > options.maxWaitTime) {
                clearInterval(intervalID);
                reject(onReject());
            }
            intervals++;
        }, options.intervalTime);
    });
}
