import { __awaiter } from "tslib";
import { sessionActionSaved } from '../store/browserStorageSlice';
import { findClientByToken, getActions, isStorageReady } from '../store/selectors';
import store from '../store/store';
import { isLocalHost } from 'utils/env';
import { sendAction } from '../storage/crossdomainStorage';
import waitForEvent from 'utils/waitForEvent';
import log from '../utils/log';
import { buildClientAction, filterActions } from '../models/Action';
import { postActiveDataAction } from 'sdk/api/activeDataManagementSystem';
const waitForActions = waitForEvent({
    eventCheck: () => isStorageReady(store.getState()),
    intervalTime: 100,
    maxWaitTime: 5000,
    // can't load in given MAX_WAIT_TIME, we're resolving anyway so the app can proceed without cross domain actions
    onReject: () => getActions(store.getState()),
    // if we have cross domain actions, we're good to go
    onResolve: () => getActions(store.getState()),
});
/** wrapper for when there's no cross domain available and local data comes in exception */
function safeGetActions() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield waitForActions();
        }
        catch (e) {
            if (Array.isArray(e)) {
                // it's an array of actions, return it
                return e;
            }
            log.error('Error getting actions', e);
        }
        // / give up
        return [];
    });
}
function getClient(token) {
    // find client that has this token
    const client = findClientByToken(token)(store.getState());
    if (!client) {
        throw new Error('Incorrect token');
    }
    return client;
}
export function getActionsForClient(token, type) {
    return __awaiter(this, void 0, void 0, function* () {
        // find client that has this token
        const client = getClient(token);
        // grab all actions and filter by CLIENT NAME and strip client info
        return filterActions(yield safeGetActions(), client, type);
    });
}
export function getAllActions(token) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!isLocalHost()) {
            // check if token is valid
            getClient(token);
        }
        // grab all actions
        return yield safeGetActions();
    });
}
export function addActiveDataAction(token, name, type, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        // the type needs to be added to the payload to properly deserialize the polymorphic objects in Java
        const action = {
            time: Date.now(),
            name,
            type,
            payload: Object.assign(Object.assign({}, (payload || {})), { _type: type }),
        };
        if (yield postActiveDataAction(action)) {
            // add to local store and whatnot
            yield addAction(token, name, type, payload);
        }
        else {
            log.error('Error posting active data action', action);
        }
    });
}
export function addAction(token, name, type, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        // find client that has this token
        const client = getClient(token);
        // build full action info
        const action = buildClientAction(client, name, type, payload);
        log.debug('New action', action);
        // save the action
        store.dispatch(sessionActionSaved(action));
        sendAction(action);
    });
}
