const TEMP_GALACTUS_COOKIE = 'ss_galactus_enabled';
export function hasTempTrackingCookie() {
    return document.cookie.includes(TEMP_GALACTUS_COOKIE);
}
export function setTrackingTempCookie() {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 12);
    // eslint-disable-next-line regex/invalid
    document.cookie = TEMP_GALACTUS_COOKIE + '=' + 'true' + ';expires=' + expires + ';domain=.fandom.com;path=/';
}
