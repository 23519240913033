import { getAdSlots, getUserContext, isAdBlockDetected } from '../store/selectors';
import store from '../store/store';
import { getPageContext, getPages } from '../store/selectors';
import { registerSlotLifecycleHandlers } from './context/slotLifecycle';
import runLater from '../../utils/runLater';
import { updatePage, updateUser } from '../store/contextSlice';
import log from '../utils/log';
import { getPageContextFromWindow, getUserContextFromWindow, getUserContextWithFallback } from '@fandom/context';
function updateUserContextFromWindow() {
    const user = getUserContextFromWindow();
    // check if user's OK now
    if (!user.isUnknown) {
        log.debug('Updating user context from window', user.toTransport());
        store.dispatch(updateUser(user));
        return user;
    }
    return false;
}
/** Fires actions unique per pageview */
function captureAndSaveContextToState() {
    // read UserContext from window
    const user = getUserContextFromWindow();
    if (user.isUnknown) {
        log.debug('Reading user context from window failed, trying fallback...');
        // we do not have context ready, try async fallback
        getUserContextWithFallback()
            .then((user) => {
            // try to load from window again and use fallback only if it failed
            if (!updateUserContextFromWindow() && !user.isUnknown) {
                log.debug('Reading user context from fallback', user.toTransport());
                store.dispatch(updateUser(user));
            }
        })
            .catch((err) => {
            // try to load from window again and use fallback only if it failed
            if (!updateUserContextFromWindow()) {
                log.error('Reading user context from fallback failed', err);
            }
        });
    }
    else {
        // update store
        log.debug('Reading user context from window', user.toTransport());
        store.dispatch(updateUser(user));
    }
    // read PageContext from window
    const page = getPageContextFromWindow();
    log.debug('Reading page context from window', page.toTransport());
    // update store
    store.dispatch(updatePage(page));
}
export function initializeContext() {
    // fire pageview
    captureAndSaveContextToState();
    // listen for ad slot events and interactions
    runLater(registerSlotLifecycleHandlers);
}
export function getContext() {
    let context = getPageContext(store.getState());
    // sometimes we do not have user context ready on boot, but it's added later to the page (eg. in nexjs apps)
    if (!context.isValid) {
        context = getPageContextFromWindow();
        // check if user's OK now
        if (context.isValid) {
            log.debug('Updated page context from window', context.toTransport());
            store.dispatch(updatePage(context));
        }
    }
    // sometimes we do not have user context ready on boot, but it's added later to the page (eg. in nexjs apps)
    let user = getUserContext(store.getState());
    if (user.isUnknown) {
        const newUser = updateUserContextFromWindow();
        if (newUser) {
            user = newUser;
        }
    }
    return {
        current: context,
        pages: getPages(store.getState()),
        features: {
            adBlockDetected: isAdBlockDetected(store.getState()),
        },
        slots: getAdSlots(store.getState()),
        user,
    };
}
