import { PageContext, UserContext } from '@fandom/context';
import { ActionType } from './models/Action/types';
export { ActionType };
export var SILVER_SURFER_EVENT;
(function (SILVER_SURFER_EVENT) {
    SILVER_SURFER_EVENT["DELAYED"] = "silver-surfer:delayed";
    SILVER_SURFER_EVENT["READY"] = "silver-surfer:ready";
})(SILVER_SURFER_EVENT || (SILVER_SURFER_EVENT = {}));
export var AdSlotStatus;
(function (AdSlotStatus) {
    AdSlotStatus["LOADED"] = "loaded";
    AdSlotStatus["COLLAPSED"] = "collapsed";
    AdSlotStatus["BLOCKED"] = "blocked";
})(AdSlotStatus || (AdSlotStatus = {}));
export var InteractionType;
(function (InteractionType) {
    InteractionType["WIKI_CONTENT"] = "wiki content";
    InteractionType["FEATURED_VIDEO"] = "featured video";
    InteractionType["DISCUSSIONS"] = "discussions";
    InteractionType["ADS"] = "ads";
})(InteractionType || (InteractionType = {}));
export var AffiliationType;
(function (AffiliationType) {
    AffiliationType["FIRST_TIME_VISITOR"] = "1st time visitor";
    AffiliationType["RETURNING_VISITOR"] = "returning visitor";
    AffiliationType["FREQUENT_VISITOR"] = "frequent visitor";
})(AffiliationType || (AffiliationType = {}));
