import { __awaiter } from "tslib";
import { countNumberOfEntities } from '../../../utils/objectHelper';
import { getAllActions, getAllPageTracks, getAllStorageSize, getInteractions } from '../../storage/storage';
import { updateLocalHistory, updateLocalStorageSize } from '../../store/browserStorageSlice';
import store from '../../store/store';
import log from '../../utils/log';
import triggerMetric from 'fandom-frontend-ready/alerts-metrics/metric';
/**
 * Read SS history from local storage (will do until cross domain kicks in)
 */
export function readLocalHistory() {
    return __awaiter(this, void 0, void 0, function* () {
        const pages = yield getAllPageTracks();
        const actions = yield getAllActions();
        const interactions = yield getInteractions();
        const localHistory = { interactions, actions, pages };
        log.debug('Read from local domain', localHistory);
        store.dispatch(updateLocalHistory(localHistory));
        // calculate local sizes
        const sizes = yield getAllStorageSize();
        store.dispatch(updateLocalStorageSize(sizes));
        const totalEntries = countNumberOfEntities(pages) + countNumberOfEntities(actions) + countNumberOfEntities([interactions]);
        triggerMetric('sdk-read-pages', pages.length, 0.02, countNumberOfEntities(pages).toString());
        triggerMetric('sdk-total-objects', totalEntries, 0.02, actions.length.toString());
        triggerMetric('sdk-storage-size', sizes.indexedDBSize, 0.02, sizes.localStorageSize.toString());
        // Try to get higher quality quotas
        try {
            if (navigator && navigator.storage && navigator.storage.estimate) {
                const estimate = yield window.navigator.storage.estimate();
                const quota = estimate.quota;
                const usage = estimate.usage;
                triggerMetric('sdk-navigator-estimate', usage, 0.02, quota.toString());
                // Only some browsers support this ability
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (estimate['usageDetails'] && estimate['usageDetails'].indexedDB) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    triggerMetric('sdk-navigator-estimate-indexeddb', estimate['usageDetails'].indexedDB, 0.02);
                }
            }
        }
        catch (e) {
            // do nothing
        }
    });
}
