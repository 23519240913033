/* eslint-disable */
// source: https://www.afasterweb.com/2017/11/20/utilizing-idle-moments/
const safeRequestIdleCallback = window['requestIdleCallback'] ||
    function (handler) {
        let startTime = Date.now();
        return window.setTimeout(function () {
            handler({
                didTimeout: false,
                timeRemaining: function () {
                    return Math.max(0, 50.0 - (Date.now() - startTime));
                },
            });
        }, 1);
    };
const safeCancelIdleCallback = window['cancelIdleCallback'] ||
    function (id) {
        window.clearTimeout(id);
    };
export { safeRequestIdleCallback, safeCancelIdleCallback };
