import { __awaiter } from "tslib";
import { addClient } from '../store/appSlice';
import store from '../store/store';
import trackSilverSurfer from '../utils/trackSilverSurfer';
import { getAllRegisteredClients } from '../store/selectors';
import { generateRegisteredClient, isClientIn, isClientValid } from '../models/SilverSurferClient';
import log from '../utils/log';
export default function registerClient(client) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!isClientValid(client)) {
            log.debug('registerClient: Invalid client', client);
            trackSilverSurfer({
                label: 'client-registration',
                action: 'invalid',
                client,
            });
            return null;
        }
        const allClients = getAllRegisteredClients(store.getState());
        // if we already have client, fail
        if (isClientIn(client, allClients)) {
            log.debug('registerClient: Already registered client', client);
            trackSilverSurfer({
                label: 'client-registration',
                action: 'already-registered',
                client,
            });
            return null;
        }
        // if we didn't have this client yet, add it and track
        const registered = generateRegisteredClient(client);
        store.dispatch(addClient(registered));
        log.debug('registerClient: Client registered', registered);
        // TODO: Enable once we have more clients
        // trackSilverSurfer({
        // 	label: 'client-registration',
        // 	action: 'registered',
        // 	client,
        // });
        return registered.token;
    });
}
