/**
 * Low-level fetch with logging.
 * @param logErrorFunction Logging function.
 * @param url URL to call.
 * @param options fetch request options.
 * @return Promise of response.
 */
export default function fetchWithLogging(logErrorFunction, url, options) {
    if (typeof options === 'undefined') {
        options = {};
    }
    return fetch(url, options).then((response) => {
        // file uploads end up here
        if (response.type === 'opaque') {
            return response;
        }
        // if we have 401 UNAUTHORIZED or 404 we don't need to log the error
        if (!response.ok && response.status !== 401 && response.status !== 404) {
            if (typeof logErrorFunction === 'function') {
                logErrorFunction(url, response);
            }
            else {
                console.error('fetchWithLogging Error', {
                    url,
                    response,
                });
            }
        }
        return response;
    });
}
