import { createSlice } from '@reduxjs/toolkit';
import runLater from '../../utils/runLater';
import { getInitialConfig } from '../utils/config';
import log from '../utils/log';
export var AppState;
(function (AppState) {
    AppState["New"] = "new";
    AppState["Starting"] = "starting";
    AppState["Delayed"] = "delayed";
    AppState["Ready"] = "ready";
})(AppState || (AppState = {}));
function logNewStateAndReturn(oldState, newState) {
    log.debug(`App state changed: ${oldState}->${newState}`);
    return newState;
}
const initialState = () => ({
    state: AppState.New,
    config: getInitialConfig(),
    clients: [],
});
export const appSlice = createSlice({
    name: 'AppSlice',
    initialState: initialState(),
    reducers: {
        starting: (state) => {
            if (state.state === AppState.New || state.state === AppState.Delayed) {
                state.state = logNewStateAndReturn(state.state, AppState.Starting);
            }
        },
        delayed: (state) => {
            if (state.state === AppState.New) {
                state.state = logNewStateAndReturn(state.state, AppState.Delayed);
            }
        },
        ready: (state) => {
            if (state.state === AppState.Starting) {
                state.state = logNewStateAndReturn(state.state, AppState.Ready);
            }
        },
        addClient: (state, action) => {
            const client = action.payload;
            state.clients.push(client);
        },
        reboot(state) {
            // only do something if app was in ready state
            if (state.state === AppState.Ready) {
                const clientRebootCallbacks = state.clients.map((client) => client === null || client === void 0 ? void 0 : client.onReboot).filter(Boolean);
                //	 schedule update for clients
                runLater(() => clientRebootCallbacks.forEach((cb) => cb()));
            }
        },
    },
});
export const appActions = appSlice.actions;
export const { starting, ready, delayed, addClient, reboot } = appSlice.actions;
export default appSlice.reducer;
