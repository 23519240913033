import { __awaiter } from "tslib";
import { sendAddInteraction } from '../storage/crossdomainStorage';
import { sessionAddInteraction } from '../store/browserStorageSlice';
import store from '../store/store';
import log from '../utils/log';
export default function addInteraction(type) {
    return __awaiter(this, void 0, void 0, function* () {
        log.debug('New interaction', type);
        store.dispatch(sessionAddInteraction(type));
        sendAddInteraction(type);
    });
}
