import trackerFactoryDataLayer, { prefixIfDev } from '../tracking/index';
import { consistentRandomNumber } from '../utils/getConsistentRandomNumber';
import { isDebug } from '../env';
const dwMetricTracker = trackerFactoryDataLayer({
    event: 'browser-client-metric',
});
const randomNumber = consistentRandomNumber();
// Calculate a metric
export default function triggerMetric(metricName, value, sample, label) {
    try {
        if (sample < randomNumber && !isDebug()) {
            return null;
        }
        return dwMetricTracker({
            category: prefixIfDev(`browser-client-metric-${metricName}`),
            label: label,
            value: value !== null && value !== void 0 ? value : metricName,
            action: 'metric',
        });
    }
    catch (e) {
        return null;
    }
}
