import { track as dataLayerPush } from './dataLayer';
import { isDebug } from '../env';
function trackWithValues(values, trackObjectFunction) {
    // root function allows for basic tracking without any defaults
    const track = (options) => {
        const combinedOptions = Object.assign(Object.assign({ nonInteractive: true }, values), options);
        trackObjectFunction(combinedOptions);
        return combinedOptions;
    };
    // Common helpers
    track.click = (options) => track(Object.assign(Object.assign(Object.assign({}, values), options), { action: 'click', nonInteractive: false }));
    track.impression = (options) => track(Object.assign(Object.assign(Object.assign({}, values), options), { action: 'impression', nonInteractive: true }));
    track.hover = (options) => track(Object.assign(Object.assign(Object.assign({}, values), options), { action: 'hover', nonInteractive: true }));
    track.loaded = (options) => track(Object.assign(Object.assign(Object.assign({}, values), options), { action: 'loaded', nonInteractive: true }));
    // Allow infinite expanding with more defaults
    track.extend = (moreDefaults) => trackWithValues(Object.assign(Object.assign({}, values), moreDefaults), trackObjectFunction);
    return track;
}
export function trackerFactory(defaultValues, trackObjectFunction) {
    const defaults = Object.assign({}, defaultValues);
    if (!defaults.event && !defaults.category) {
        console.error('[Tracking] Missing event or category field', defaults);
        throw new Error('Missing event or category field');
    }
    // if not category provided but there is an event. Set category to event
    if (!defaults.category) {
        defaults.category = defaults.event;
    }
    // ensure we have an event set
    if (!defaults.event) {
        defaults.event = defaults.category;
    }
    return trackWithValues(defaults, trackObjectFunction);
}
export default function trackerFactoryDataLayer(defaultValues) {
    return trackerFactory(defaultValues, dataLayerPush);
}
// export a simple tracker if needed - prefer using the factory above
export const tracker = trackerFactory({ category: 'base-tracker' }, dataLayerPush);
export function prefixIfDev(property) {
    return `${isDebug() ? 'dev-' : ''}${property}`;
}
