import isRegisteredGTMEvent from 'fandom-frontend-ready/tracking/isRegisteredGTMEvent';
import DW_ENABLED_VALUES from './DataWarehouseEnabledValues';
function isDataLayerReady() {
    var _a, _b, _c;
    return (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.google_tag_manager) === null || _a === void 0 ? void 0 : _a.dataLayer) === null || _b === void 0 ? void 0 : _b.gtmDom) !== null && _c !== void 0 ? _c : false;
}
function attemptMigratePreDataLayer() {
    if (!isDataLayerReady()) {
        return; // don't migrate when it's not ready
    }
    window.__datalayer_pre = window.__datalayer_pre || [];
    if (window.__datalayer_pre.length > 0) {
        if (window.__datalayer_pre_interval_id) {
            window.clearInterval(window.__datalayer_pre_interval_id);
        }
        window.__datalayer_pre.forEach((prevItem) => window.dataLayer.push(prevItem));
        window.__datalayer_pre = []; // empty array
    }
    if (window.__datalayer_pre_interval_id) {
        window.clearInterval(window.__datalayer_pre_interval_id);
        window.__datalayer_pre_interval_id = null;
    }
}
// Keep checking
if (!window.__datalayer_pre_interval_id && !isDataLayerReady()) {
    window.__datalayer_pre_interval_id = window.setInterval(attemptMigratePreDataLayer, 500);
}
function pushToDataLayer(data) {
    window.__datalayer_pre = window.__datalayer_pre || [];
    // window.__gtm_state will initial be set to INITIAL and then changed to READY
    if (!isDataLayerReady()) {
        return window.__datalayer_pre.push(data);
    }
    attemptMigratePreDataLayer();
    // default
    return window.dataLayer.push(data);
}
// generic track function
export function track(data) {
    // enforce true/false
    data.isDebug = !!data.isDebug;
    // if we don't have "event" field, complain
    if (!data.event) {
        console.error("[Tracking] missing 'event' field", data);
    }
    else {
        window.dataLayer = window.dataLayer || [];
        // if we didn't have category, equal it to the event (follow video events on MW pages as a guideline)
        if (!data.category) {
            data.category = data.event;
        }
        // GTM Bug - Always ensure we have a label
        if (!data.label) {
            data.label = data.category;
        }
        // Check for validated DW enabled values
        if (data.isDebug) {
            if (!isRegisteredGTMEvent(data.event)) {
                console.warn('[Tracking] GTM event not caught', data.event);
            }
            const validDWValues = DW_ENABLED_VALUES;
            const dataKeys = Object.keys(data);
            dataKeys.every((item) => {
                if (!validDWValues.includes(item) && !data.skipValidation) {
                    console.warn('[Tracking] Element not enabled in DW', item);
                    return false;
                }
                return true;
            });
        }
        return pushToDataLayer(data);
    }
    // log here
    return null;
}
