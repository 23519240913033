export default function getDevices(profile) {
    var _a, _b, _c;
    const galactusDevices = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.namespaces) === null || _a === void 0 ? void 0 : _a.devices) === null || _b === void 0 ? void 0 : _b.set) !== null && _c !== void 0 ? _c : [];
    return galactusDevices.map((device) => {
        return {
            type: getDeviceType(device),
            platform: getDevicePlatform(device),
            browser: getDeviceBrowser(device),
        };
    });
}
// library used for Device Detection: https://github.com/thinkwelltwd/device_detector
// type: mobile, desktop, tablet, console
function getDeviceType(device) {
    const type = device.type.toLowerCase();
    return type.replace('unknown', 'other');
}
// platform: apple, android, other
function getDevicePlatform(device) {
    const os = device.os.toLowerCase();
    const make = device.make.toLowerCase();
    let platform = 'other';
    if (os.includes('android')) {
        platform = 'android';
    }
    if (make.includes('apple')) {
        platform = 'apple';
    }
    return platform;
}
// browser: chrome, safari, edge, other
function getDeviceBrowser(device) {
    const browser = device.browser.toLowerCase();
    return browser.replace('unknown', 'other');
}
