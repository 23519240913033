import { PageContext, UserContext } from '@fandom/context';
import { createSlice } from '@reduxjs/toolkit';
import log from '../utils/log';
const initialState = () => ({
    user: UserContext.getDefault(),
    page: PageContext.getDefault(),
    adBlockDetected: null,
    adSlots: {},
});
export const contextSlice = createSlice({
    name: 'ContextSlice',
    initialState: initialState(),
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        updatePage: (state, action) => {
            state.page = action.payload;
        },
        adSlotUpdate(state, action) {
            state.adSlots[action.payload.name] = action.payload.status;
        },
        adBlockDetected: (state, action) => {
            state.adBlockDetected = action.payload;
            log.debug('Feature: AdBlock Detected', state.adBlockDetected);
        },
    },
});
export const localContextActions = contextSlice.actions;
export const { updateUser, updatePage, adSlotUpdate, adBlockDetected } = contextSlice.actions;
export default contextSlice.reducer;
