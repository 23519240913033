export var RegisterMessage;
(function (RegisterMessage) {
    RegisterMessage["Request"] = "CrossDomain::Register::Request";
    RegisterMessage["Response"] = "CrossDomain::Register::Response";
})(RegisterMessage || (RegisterMessage = {}));
export var Status;
(function (Status) {
    Status["Off"] = "off";
    Status["Initializing"] = "initializing";
    Status["Ready"] = "ready";
})(Status || (Status = {}));
