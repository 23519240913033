import { buildRegisterRequestEvent, validateMessage } from './messages';
import { RegisterMessage, Status } from './types';
export default class CrossDomainSender {
    constructor(targetOrigin, targetUrl, onMessageCallback, options) {
        this._sending = false;
        this._queue = [];
        this._options = {};
        this._onMessageCallback = onMessageCallback;
        this._origin = targetOrigin;
        this._options = options;
        this._initialize(targetUrl);
    }
    get status() {
        return this._status;
    }
    _log(str, ...args) {
        var _a;
        if ((_a = this._options) === null || _a === void 0 ? void 0 : _a.debug) {
            console.log(`CrossDomainSender::${str}`, ...args);
        }
    }
    _initialize(url) {
        this._log('_initialize');
        this._status = Status.Initializing;
        // create message channel
        // initialize iframe and add it to the page
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        // @ts-expect-error ts(2540): sandbox is readonly - it isn't here
        iframe.sandbox = 'allow-same-origin allow-scripts';
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        iframe.addEventListener('load', this._onIframeLoad.bind(this));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        iframe.addEventListener('error', this._onIframeLoadError.bind(this));
        iframe.src = url;
        document.body.appendChild(iframe);
        // save it for later
        this._iframe = iframe;
    }
    _onIframeLoadError(ev) {
        var _a;
        this._log('_onIframeLoadError', ev.error);
        this._status = Status.Off;
        if (typeof ((_a = this._options) === null || _a === void 0 ? void 0 : _a.onIframeError) === 'function') {
            this._options.onIframeError(ev);
        }
    }
    _onIframeLoad() {
        this._log('_onIframeLoad');
        const channel = new MessageChannel();
        // iframe loaded; finish setting up the channel
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        channel.port1.onmessage = this._onMessage.bind(this);
        // send the register mesage (need to transfer the port)
        this._sendMessage(buildRegisterRequestEvent(), [channel.port2]);
    }
    _onMessage(ev) {
        if (validateMessage(ev.data)) {
            this._log('_onMessage', ev);
            // if that's register response, we're ready now
            if (ev.data.type === RegisterMessage.Response) {
                this._status = Status.Ready;
            }
            // send the response down the pipe
            if (typeof this._onMessageCallback === 'function') {
                this._onMessageCallback(ev.data);
            }
        }
        else {
            this._log('_onMessage - invalid message', ev);
        }
    }
    _sendMessage(message, transfer) {
        this._log('_sendMessage', message);
        this._iframe.contentWindow.postMessage(message, this._origin, transfer);
    }
    _flushQueue() {
        if (this._status === Status.Ready && !this._sending) {
            this._sending = true;
            let message;
            // eslint-disable-next-line no-constant-condition
            while (1) {
                message = this._queue.shift();
                if (typeof message === 'undefined') {
                    break;
                }
                this._sendMessage(message);
            }
            this._sending = false;
        }
        // do nothing
    }
    send(event) {
        this._queue.push(event);
        this._flushQueue();
    }
}
