import uniq from 'lodash/uniq';
export default function getConsoles(adTags, userProfile) {
    var _a, _b, _c, _d;
    return uniq([]
        .concat(
    // Galactus response
    Object.keys((_c = (_b = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.namespaces) === null || _a === void 0 ? void 0 : _a.adTags) === null || _b === void 0 ? void 0 : _b.pform) !== null && _c !== void 0 ? _c : []), 
    // page ad tags
    Object.keys((_d = adTags === null || adTags === void 0 ? void 0 : adTags.pform) !== null && _d !== void 0 ? _d : []))
        .filter(Boolean));
}
