import { __awaiter } from "tslib";
import genUID from 'utils/genUID';
import { getCookieByName } from 'utils/getCookieByName';
const ALLOWED_TYPES = ['default', 'uuid'];
const nameToCookieName = (name) => `fid_${name}`;
export function getGlobalId(name, type = 'default') {
    return __awaiter(this, void 0, void 0, function* () {
        if (type && !ALLOWED_TYPES.includes(type)) {
            throw new Error(`Invalid type: ${type}`);
        }
        // name can only have alphanumeric characters and underscore
        if (!name.match(/^[a-zA-Z0-9_]+$/)) {
            throw new Error(`Invalid name: ${name}`);
        }
        // check if we're on fandom.com domain - only this makes sense for now
        if (!window.location.hostname.endsWith('fandom.com')) {
            throw new Error('Global ID is only available on fandom.com domain');
        }
        // TODO: This is temporary solution, we need to move this to API
        // read value from cookie
        let value = getCookieByName(nameToCookieName(name));
        if (!value) {
            switch (type) {
                case 'uuid':
                    value = genUID();
                    break;
                default:
                    // default is still uuid
                    value = genUID();
            }
        }
        // TODO: This is temporary solution, we need to move this to API
        // save it back to cookie on fandom.com for 5 years
        // eslint-disable-next-line regex/invalid
        document.cookie = `${nameToCookieName(name)}=${value}; path=/; domain=.fandom.com; max-age=${60 * 60 * 24 * 365 * 5}; SameSite=Lax`;
        return value;
    });
}
