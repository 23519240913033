import uniq from 'lodash/uniq';
import { getPageContext, getPages, getUserContext } from '../../store/selectors';
import store from '../../store/store';
export default function getLanguage(profile) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const contentLanguages = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.namespaces) === null || _a === void 0 ? void 0 : _a.languages) === null || _b === void 0 ? void 0 : _b.contentLanguages) !== null && _c !== void 0 ? _c : [];
    const acceptLanguages = (_f = (_e = (_d = profile === null || profile === void 0 ? void 0 : profile.namespaces) === null || _d === void 0 ? void 0 : _d.languages) === null || _e === void 0 ? void 0 : _e.acceptLanguages) !== null && _f !== void 0 ? _f : [];
    const fallbackMediawiki = [(_g = getPageContext(store.getState())) === null || _g === void 0 ? void 0 : _g.pageLanguage, (_h = getUserContext(store.getState())) === null || _h === void 0 ? void 0 : _h.lang];
    const fallbackWindow = [window.navigator.language.split('-')[0]];
    const pages = getPages(store.getState());
    return uniq([]
        .concat(
    // galactus response
    contentLanguages.map((lang) => lang.languageCode), acceptLanguages.map((lang) => lang.languageCode), 
    // pages data
    pages.map((p) => p === null || p === void 0 ? void 0 : p.pageLanguage), 
    // mediawiki data
    fallbackMediawiki, 
    // fallback from window
    fallbackWindow)
        .filter(Boolean)
        .map((lang) => lang.toLowerCase()));
}
