import { SilverSurferMessage, buildAddActionRequestEvent, buildAddInteractionRequestEvent, buildPageViewRequestEvent, } from './crossdomainMesages';
import CrossDomainSender from './crossdomain/CrossDomainSender';
import { RegisterMessage, Status } from './crossdomain/types';
import store from '../store/store';
import { crossdomainStatus, updateCrossdomainActions, updateCrossdomainInteractions, updateCrossdomainPages, updateCrossdomainSize, } from '../store/browserStorageSlice';
import { configCanSendDebugLog, configHasCrossdomainDisabled } from '../store/selectors';
import { hasQueryParam, isLocalHost, IS_PRODUCTION } from 'utils/env';
import log from '../utils/log';
import { CROSS_DOMAIN_HOST } from './consts';
function getOrigin() {
    // if SS runs on different port, it won't connect to cross domain storage anyway, give up
    if (window.location.port !== '' && window.location.port !== '80') {
        return null;
    }
    // otherwise, we're on production (probably)
    if (IS_PRODUCTION) {
        return `https://${CROSS_DOMAIN_HOST}`;
    }
    // if we're on localhost
    if (isLocalHost() && hasQueryParam('ss_force_crossdomain=true')) {
        return `${window.location.protocol}//${window.location.host}`;
    }
    // it's unsupported environment, give up
    return null;
}
let sender;
function buildCrossdomainStorageUrl() {
    let search = window.location.search;
    if (configCanSendDebugLog(store.getState())) {
        search += `${search.length ? '&' : '?'}cb=${Date.now()}`;
    }
    return `${getOrigin()}/silver-surfer.html${search}`;
}
const MAX_WAIT_TIME = isLocalHost() ? 500 : 14000;
export function initialize() {
    try {
        // only run once
        if (!sender) {
            if (configHasCrossdomainDisabled(store.getState())) {
                log.debug('Cross domain connection disabled (on purpose)');
                store.dispatch(crossdomainStatus({ status: Status.Off, statusErrorMessage: 'disabled' }));
                return;
            }
            // if we're on unsupported domain, just disable cross domain
            if (!getOrigin()) {
                log.debug('Cross domain connection disabled (unsupported origin)');
                store.dispatch(crossdomainStatus({ status: Status.Off, statusErrorMessage: 'incorrect-origin' }));
                return;
            }
            log.debug('Initializing cross domain connection');
            // try open cross domain communication
            sender = new CrossDomainSender(getOrigin(), buildCrossdomainStorageUrl(), onMessageCallback, {
                debug: configCanSendDebugLog(store.getState()),
                onIframeError: (ev) => {
                    store.dispatch(crossdomainStatus({ status: Status.Off, statusErrorMessage: 'iframe-error' }));
                    log.error('Cross domain frame error');
                },
            });
            // .. but only wait MAX_WAIT_TIME ms for it, after that consider it initialized
            setTimeout(() => {
                store.dispatch(crossdomainStatus({ status: Status.Off, statusErrorMessage: 'timeout' }));
                log.error('Cross domain timeout');
            }, MAX_WAIT_TIME);
        }
    }
    catch (e) {
        log.error('Cross domain error thrown', e);
        store.dispatch(crossdomainStatus({ status: Status.Off, statusErrorMessage: 'unknown' }));
    }
}
function onMessageCallback(message) {
    switch (message.type) {
        case RegisterMessage.Response: {
            // this response is fired instantly after connecting to cross domain, let SS know, we're ready
            store.dispatch(crossdomainStatus({ status: Status.Ready }));
            log.debug('Cross domain connected');
            return;
        }
        case SilverSurferMessage.HistorySizeResponse: {
            const storageSize = message.payload;
            store.dispatch(updateCrossdomainSize(storageSize));
            log.debug('Cross domain sent storage size', storageSize);
            return;
        }
        case SilverSurferMessage.HistoryInteractionsResponse: {
            const history = message.payload;
            store.dispatch(updateCrossdomainInteractions(history));
            log.debug('Cross domain sent history interactions', history);
            return;
        }
        case SilverSurferMessage.HistoryPagesResponse: {
            const history = message.payload;
            store.dispatch(updateCrossdomainPages(history));
            log.debug('Cross domain sent history pages', history);
            return;
        }
        case SilverSurferMessage.HistoryActionsResponse: {
            const history = message.payload;
            store.dispatch(updateCrossdomainActions(history));
            log.debug('Cross domain sent history actions', history);
            return;
        }
        case SilverSurferMessage.PageviewResponse: {
            log.debug('Cross domain pageview ack');
            return;
        }
        case SilverSurferMessage.AddActionResponse: {
            log.debug('Cross domain action ack');
            return;
        }
        case SilverSurferMessage.AddInteractionResponse: {
            log.debug('Cross domain interaction ack');
            return;
        }
        default: {
            log.error('Unknown message type', message);
            return;
        }
    }
}
export function sendPageView(pageData) {
    if (!!sender && sender.status === Status.Ready) {
        log.debug('Tracking cross domain page', pageData);
        sender.send(buildPageViewRequestEvent(pageData));
    }
    else {
        log.debug('Cross domain not ready on pageview');
    }
}
export function sendAction(action) {
    if (!!sender && sender.status === Status.Ready) {
        log.debug('Sending cross domain action', action);
        sender.send(buildAddActionRequestEvent(action));
    }
    else {
        log.debug('Cross domain not ready on action');
    }
}
export function sendAddInteraction(type) {
    if (!!sender && sender.status === Status.Ready) {
        log.debug('Sending cross domain interaction', type);
        sender.send(buildAddInteractionRequestEvent(type));
    }
    else {
        log.debug('Cross domain not ready on interaction');
    }
}
