import { __rest } from "tslib";
import genUID from 'utils/genUID';
import { SS_VERSION } from '../../../utils/env';
// this token will also be saved along with Actions
export function generateRegisteredClient(client) {
    return Object.assign(Object.assign({}, client), { time: Date.now(), token: genUID(), ssVersion: SS_VERSION });
}
export function isClientValid(client) {
    if (!client) {
        return false;
    }
    // name is required
    if (typeof client.name !== 'string') {
        return false;
    }
    // onReboot needs to be a function
    if ((client === null || client === void 0 ? void 0 : client.onReboot) && typeof client.onReboot !== 'function') {
        return false;
    }
    return true;
}
export function getSerializableClient(client) {
    // we're going to remove onReboot from the client via deserialization, we also cast type because we know it's a SilverSurferClient anyway
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onReboot } = client, serializableClient = __rest(client, ["onReboot"]);
    return serializableClient;
}
export function isClientEqual(a, b) {
    return (a === null || a === void 0 ? void 0 : a.name) === (b === null || b === void 0 ? void 0 : b.name);
}
export function findClientIn(needle, stack) {
    var _a;
    return (_a = stack === null || stack === void 0 ? void 0 : stack.findIndex((c) => isClientEqual(c, needle))) !== null && _a !== void 0 ? _a : -1;
}
export function isClientIn(needle, stack) {
    return findClientIn(needle, stack) > -1;
}
