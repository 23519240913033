import { buildMessage } from './crossdomain/messages';
export var SilverSurferMessage;
(function (SilverSurferMessage) {
    SilverSurferMessage["PageviewRequest"] = "SilverSurfer::Pageview::Request";
    SilverSurferMessage["PageviewResponse"] = "SilverSurfer::Pageview::Response";
    SilverSurferMessage["AddActionRequest"] = "SilverSurfer::AddAction::Request";
    SilverSurferMessage["AddActionResponse"] = "SilverSurfer::AddAction::Response";
    SilverSurferMessage["AddInteractionRequest"] = "SilverSurfer::AddInteraction::Request";
    SilverSurferMessage["AddInteractionResponse"] = "SilverSurfer::AddInteraction::Response";
    SilverSurferMessage["HistorySizeResponse"] = "SilverSurfer::HistorySize::Response";
    SilverSurferMessage["HistoryPagesResponse"] = "SilverSurfer::HistoryPages::Response";
    SilverSurferMessage["HistoryActionsResponse"] = "SilverSurfer::HistoryActions::Response";
    SilverSurferMessage["HistoryInteractionsResponse"] = "SilverSurfer::HistoryInteractions::Response";
})(SilverSurferMessage || (SilverSurferMessage = {}));
export function buildPageViewRequestEvent(page) {
    return buildMessage(SilverSurferMessage.PageviewRequest, page);
}
export function buildPageViewResponseEvent() {
    return buildMessage(SilverSurferMessage.PageviewResponse);
}
export function buildAddActionRequestEvent(action) {
    return buildMessage(SilverSurferMessage.AddActionRequest, action);
}
export function buildAddActionResponseEvent() {
    return buildMessage(SilverSurferMessage.AddActionResponse);
}
export function buildAddInteractionRequestEvent(type) {
    return buildMessage(SilverSurferMessage.AddInteractionRequest, type);
}
export function buildAddInteractionResponseEvent() {
    return buildMessage(SilverSurferMessage.AddInteractionResponse);
}
export function buildHistorySizeResponseEvent(storageSize) {
    return buildMessage(SilverSurferMessage.HistorySizeResponse, storageSize);
}
export function buildHistoryPagesResponseEvent(history) {
    return buildMessage(SilverSurferMessage.HistoryPagesResponse, history);
}
export function buildHistoryActionsResponseEvent(history) {
    return buildMessage(SilverSurferMessage.HistoryActionsResponse, history);
}
export function buildHistoryInteractionsResponseEvent(history) {
    return buildMessage(SilverSurferMessage.HistoryInteractionsResponse, history);
}
