import { __awaiter } from "tslib";
import { getGalactusUserProfileForCurrentUser } from './getGalactusUserProfile';
import store from '../../store/store';
import { getPages, getPageContext, getInteractionsArray, getVisitedProducts } from 'sdk/store/selectors';
import getConsoles from './consolesHelper';
import getDevices from './devicesHelper';
import getCountry from './countryHelper';
import getLanguage from './languageHelper';
import getAffiliation from './affiliationHelper';
import getStreaming from './streamingHelper';
import { isEmptyObject } from 'utils/objectHelper';
import getUserInterests from './interestsHelper';
import log from '../../utils/log';
import { getActiveData } from 'sdk/modules/userProfile/getActiveData';
import { flatAdTagsToCounted, getWikiaBeaconId, sumAdTags } from '@fandom/context';
function getAdTags(galactusUserProfile) {
    var _a, _b, _c;
    // best source - Galactus
    let adTags = (_b = (_a = galactusUserProfile === null || galactusUserProfile === void 0 ? void 0 : galactusUserProfile.namespaces) === null || _a === void 0 ? void 0 : _a.adTags) !== null && _b !== void 0 ? _b : {};
    // next source - use backup from tracked pages
    if (!isEmptyObject(adTags)) {
        log.debug('getUserProfile: Galactus AdTags are empty, fallback to history');
        const pages = getPages(store.getState());
        const allAdTagsFromHistory = (_c = pages.map(({ adTags }) => adTags)) !== null && _c !== void 0 ? _c : [];
        adTags = sumAdTags(allAdTagsFromHistory.filter(Boolean).map(flatAdTagsToCounted));
    }
    // fallback - PageContext
    if (isEmptyObject(adTags)) {
        log.debug('getUserProfile: History is empty, fallback to current page');
        adTags = flatAdTagsToCounted(getPageContext(store.getState()).adTags);
    }
    return adTags;
}
export default function getUserProfile() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const galactusUserProfile = yield getGalactusUserProfileForCurrentUser();
        const activeDataObject = yield getActiveData();
        const adTags = getAdTags(galactusUserProfile);
        // combine with local data here to ensure we always return a profile
        const profile = {
            beaconId: getWikiaBeaconId(),
            gender: Object.keys((_a = adTags === null || adTags === void 0 ? void 0 : adTags.sex) !== null && _a !== void 0 ? _a : {}),
            affiliation: getAffiliation(adTags),
            interactions: getInteractionsArray(store.getState()),
            ageBrackets: Object.keys((_b = adTags === null || adTags === void 0 ? void 0 : adTags.age) !== null && _b !== void 0 ? _b : {}),
            devices: getDevices(galactusUserProfile),
            language: getLanguage(galactusUserProfile),
            country: getCountry(galactusUserProfile),
            interests: getUserInterests(adTags),
            consoles: getConsoles(adTags, galactusUserProfile),
            streaming: getStreaming(adTags, galactusUserProfile),
            adTags,
            activeData: activeDataObject,
            visitedProducts: getVisitedProducts(store.getState()),
        };
        log.debug('getUserProfile: Profile complete', profile);
        return profile;
    });
}
