import { __awaiter } from "tslib";
import takeRight from 'lodash/takeRight';
import omit from 'lodash/omit';
import { GALACTUS_API_VERSION } from '../../models/Galactus';
import { LOCALSTORAGE_MAX_ENTRIES, StorageType, STORAGE_ACTIONS_LIMIT, STORAGE_PAGEVIEW_LIMIT } from '../storage';
const VERSION = 2;
const SILVER_SURFER_TRACK_KEY = 'silver_surfer_tracking_key_v' + VERSION;
const SILVER_SURFER_ACTIONS_KEY = 'silver_surfer_actions_v' + VERSION;
const GALACTUS_FALLBACK_USER_PROFILE_KEY = 'silver-surfer-galactus-fallback-user-profile-v' + VERSION;
const SILVER_SURFER_INTERACTIONS_KEY = 'silver_surfer_interactions_v' + VERSION;
export function getDatabaseSize() {
    return __awaiter(this, void 0, void 0, function* () {
        // a list of all data that's stored in local
        const storedData = [yield getAllPageTracks(), yield getAllActions(), yield getInteractions()];
        return storedData.map((d) => JSON.stringify(d).length).reduce((sum, current) => sum + current, 0) * 4;
    });
}
const getGalactusUserProfileKey = (beaconId) => `${GALACTUS_FALLBACK_USER_PROFILE_KEY}_${beaconId}`;
export function loadGalactusUserProfile(beaconId) {
    return __awaiter(this, void 0, void 0, function* () {
        const cachedData = localStorage.getItem(getGalactusUserProfileKey(beaconId));
        if (cachedData) {
            const userProfileObject = JSON.parse(cachedData);
            // if we fetched the object and the version is the same
            if (userProfileObject && userProfileObject.apiVersion === GALACTUS_API_VERSION) {
                return omit(userProfileObject, 'apiVersion');
            }
        }
        return null;
    });
}
export function saveGalactusUserProfile(beaconId, userProfile) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            localStorage.setItem(getGalactusUserProfileKey(beaconId), JSON.stringify(Object.assign(Object.assign({}, userProfile), { apiVersion: GALACTUS_API_VERSION })));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function addInteraction(type) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const interactions = yield getInteractions();
        const count = (_a = interactions[type]) !== null && _a !== void 0 ? _a : 0;
        interactions[type] = count + 1;
        try {
            localStorage.setItem(SILVER_SURFER_INTERACTIONS_KEY, JSON.stringify(interactions));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function setInteractions(interactions) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            localStorage.setItem(SILVER_SURFER_INTERACTIONS_KEY, JSON.stringify(interactions));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function getInteractions() {
    return __awaiter(this, void 0, void 0, function* () {
        const interactions = localStorage.getItem(SILVER_SURFER_INTERACTIONS_KEY);
        if (interactions) {
            return JSON.parse(interactions);
        }
        return {};
    });
}
export function appendPageTrack(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const pageTracking = yield getAllPageTracks();
        pageTracking.push(entry);
        try {
            localStorage.setItem(SILVER_SURFER_TRACK_KEY, JSON.stringify(takeRight(pageTracking, LOCALSTORAGE_MAX_ENTRIES)));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function setAllPageTracks(entries) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            localStorage.setItem(SILVER_SURFER_TRACK_KEY, JSON.stringify(takeRight(entries, STORAGE_PAGEVIEW_LIMIT[StorageType.LocalStorage])));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function getAllPageTracks() {
    return __awaiter(this, void 0, void 0, function* () {
        const items = localStorage.getItem(SILVER_SURFER_TRACK_KEY);
        if (items) {
            return JSON.parse(items);
        }
        return [];
    });
}
export function addAction(entry) {
    return __awaiter(this, void 0, void 0, function* () {
        const actions = yield getAllActions();
        actions.push(entry);
        try {
            localStorage.setItem(SILVER_SURFER_ACTIONS_KEY, JSON.stringify(takeRight(actions, LOCALSTORAGE_MAX_ENTRIES)));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function setActions(actions) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            localStorage.setItem(SILVER_SURFER_ACTIONS_KEY, JSON.stringify(takeRight(actions, STORAGE_ACTIONS_LIMIT[StorageType.LocalStorage])));
        }
        catch (e) {
            // ignore error
            return false;
        }
        return true;
    });
}
export function getAllActions() {
    return __awaiter(this, void 0, void 0, function* () {
        const items = localStorage.getItem(SILVER_SURFER_ACTIONS_KEY);
        if (items) {
            return JSON.parse(items);
        }
        return [];
    });
}
const MAX_LOCALSTORAGE_SIZE = 5000000;
export function getUsedStoragePercent() {
    return __awaiter(this, void 0, void 0, function* () {
        const dbSize = yield getDatabaseSize();
        return (dbSize / MAX_LOCALSTORAGE_SIZE) * 100;
    });
}
