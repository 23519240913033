import { __awaiter } from "tslib";
import { isPageContextEqual, PageContext } from '@fandom/context';
import { SS_EVENTS } from 'fandom-frontend-ready/timing/registry';
import record from 'fandom-frontend-ready/timing/timings';
import { SS_VERSION } from 'utils/env';
import observeStore from 'utils/observeStore';
import runLater from 'utils/runLater';
import { pageTrackEntryFromPageContext } from '../models/PageTrackEntry';
import { readLocalHistory } from '../modules/history/localHistory';
import { trackCrossdomainPageView, trackLocalPageView } from '../modules/history/trackPageView';
import { initialize as initializeCrossDomain } from '../storage/crossdomainStorage';
import { setActions, setAllPageTracks, setInteractions } from '../storage/storage';
import { appActions } from '../store/appSlice';
import { canSilverSurferBeReady, configCanSendTracking, configHasManualPageview, getActions as getActionsFromStore, getConfig, getCrossdomainStatus, getCrossdomainStatusMessage, getInteractions as getInteractionsFromStore, getPages as getPagesFromStore, isCrossdomainFinished, isCrossdomainIntialized, isCrossdomainReady, isSilverSurferDelayed, isSilverSurferNew, isSilverSurferReady, } from '../store/selectors';
import store from '../store/store';
import { SILVER_SURFER_EVENT } from '../types';
import { fireCustomEvent } from '../utils/custom-events';
import log from '../utils/log';
import { hasTempTrackingCookie, setTrackingTempCookie } from '../utils/tempTrackingCookie';
import { initializeContext } from './getContext';
import getUserProfile from './userProfile/getUserProfile';
function setupPostBootActions() {
    // add an obsever, complete the bootup only after there's a local context
    const unsubscribeFromReady = observeStore(store, canSilverSurferBeReady, (isReady) => __awaiter(this, void 0, void 0, function* () {
        if (isReady) {
            // remove listener
            unsubscribeFromReady();
            log.debug('Booted, running post-boot actions');
            // schedule updating storages
            setupUpdateDomainStorages();
            // actions that were postponed
            trackLocalPageView().catch(log.error);
            // fetch first version of the Profile (will also fire chain to get the profile from Galactus)
            getUserProfile().catch(log.error);
            if (configCanSendTracking(store.getState()) || hasTempTrackingCookie()) {
                if (!hasTempTrackingCookie()) {
                    setTrackingTempCookie();
                }
            }
            // dispatch post-boot actions
            store.dispatch(appActions.ready());
            // fire metrics!
            record(SS_EVENTS.SS_SDK_READY);
            // fire ready custom event
            fireCustomEvent(SILVER_SURFER_EVENT.READY);
            // lazy initialize cross domain
            runLater(initializeCrossDomain);
        }
    }));
}
/** Update local domain storage when full data comes from the cross domain */
function setupUpdateDomainStorages() {
    // whenever the pages change, update local domain data
    observeStore(store, getPagesFromStore, (pages) => {
        setAllPageTracks(pages.map(pageTrackEntryFromPageContext)).then(() => {
            log.debug('LocalStorage pages saved', pages);
        });
    });
    // whenever the actions change, update local domain data
    observeStore(store, getActionsFromStore, (actions) => {
        setActions(actions).then(() => {
            log.debug('LocalStorage actions saved', actions);
        });
    });
    // whenever the interactions change, update local domain data
    observeStore(store, getInteractionsFromStore, (interactions) => {
        setInteractions(interactions).then(() => {
            log.debug('LocalStorage interactions saved', interactions);
        });
    });
}
function setupPostCrossdomainActions() {
    // add an observer, complete cross domain once we're done
    const unsubscribeFromCrossDomainInitialized = observeStore(store, isCrossdomainIntialized, (isInitialized) => {
        if (isInitialized) {
            record(SS_EVENTS.SS_CROSS_DOMAIN_READY);
            // remove listener
            unsubscribeFromCrossDomainInitialized();
            log.debug('Cross domain initialized:', getCrossdomainStatus(store.getState()), getCrossdomainStatusMessage(store.getState()));
            // dispatch post-crossdomain actions
            if (!configHasManualPageview(store.getState())) {
                trackCrossdomainPageView().catch(log.error);
            }
        }
    });
    const unsubscribeFromCrossDomainFinished = observeStore(store, isCrossdomainFinished, (isFinished) => {
        if (isFinished) {
            // remove listener
            unsubscribeFromCrossDomainFinished();
            log.debug('Cross domain finished');
        }
    });
}
let previousPageContext = null;
export function trackPageview() {
    return __awaiter(this, void 0, void 0, function* () {
        // two kinds of pageviews - first one on SPA and subsequent - we can figure out which one is it because subsequent pageviews are made on Ready app state
        const isDelayed = isSilverSurferDelayed(store.getState());
        const isReady = isSilverSurferReady(store.getState());
        if (!isDelayed && !isReady) {
            log.error('trackPageview cannot be triggered until SilverSurfer is bootstrapped');
            return false;
        }
        // triggering the pageview only when the context changes
        // discussions sometimes fire the pageview twice, so we need to check if the context is the same as the previous one
        const newPageContext = PageContext.fromWindow();
        if (isPageContextEqual(newPageContext, previousPageContext)) {
            log.info('Page context did not change, skipping pageview');
            return false;
        }
        log.debug('Firing manual pageview');
        // read new data from the context and save it
        initializeContext();
        // save pageview in current domain storage
        trackLocalPageView().catch(log.error);
        // exclusive to subsequent pageviews
        if (isReady) {
            // send pageview to the cross domain if cross domain is initialized (but only in subsequent pageviews, first PV is sent on Ready in different callback)
            if (isCrossdomainReady(store.getState())) {
                trackCrossdomainPageView().catch(log.error);
            }
            // notify all the clients that SS 'rebooted'
            store.dispatch(appActions.reboot());
        }
        if (isDelayed) {
            // if that's a first PV, we're ready, proceed with the rest of the app
            store.dispatch(appActions.starting());
        }
        previousPageContext = newPageContext;
        // we're OK
        return true;
    });
}
export function bootstrap() {
    if (isSilverSurferNew(store.getState())) {
        log.debug(`Booting SilverSurfer #${SS_VERSION}`, getConfig(store.getState()));
        // setup observers for app lifecycle
        setupPostBootActions();
        setupPostCrossdomainActions();
        // bootstrap/initialize all needed functionalities
        readLocalHistory().catch(log.error);
        // if the're need for manual pageview the starting will be delayed
        if (!configHasManualPageview(store.getState())) {
            initializeContext();
            // save pageview in current domain storage
            trackLocalPageView().catch(log.error);
            store.dispatch(appActions.starting());
        }
        else {
            log.info('Manual pageview is enabled');
            store.dispatch(appActions.delayed());
            // fire delayed custom event
            fireCustomEvent(SILVER_SURFER_EVENT.DELAYED);
        }
    }
}
