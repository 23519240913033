import consoleLoggerFactory from '../../utils/consoleLoggerFactory';
function getStyles(color) {
    return ['background-color: silver;', 'padding: 2px 4px', 'border-radius: 2px', `color: ${color}`];
}
export default {
    /** General important information (the fewer=the better) */
    info: consoleLoggerFactory('SilverSurfer', 'info', getStyles('#000')),
    /** Debug info (only visible with debug param) */
    debug: consoleLoggerFactory('SilverSurfer', 'debug', getStyles('#330')),
    /** Errors (always visible) */
    error: consoleLoggerFactory('SilverSurfer', 'error', getStyles('#300')),
};
