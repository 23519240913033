import getInitTime from './getInitTime';
import getCurrentTime from './getCurrentTime';
import { trackDelta, trackSingleTime } from 'fandom-frontend-ready/timing/trackTiming';
import { isDebug } from 'fandom-frontend-ready/env';
// Storage of events
let timeMappingMap = new Map();
export function resetTimings() {
    timeMappingMap = new Map();
}
export function getTimeOf(event) {
    var _a;
    return (_a = timeMappingMap.get(event)) !== null && _a !== void 0 ? _a : null;
}
function addTime(event, timeSince0) {
    timeMappingMap.set(event, timeSince0);
    trackSingleTime(event, timeSince0);
}
export default function record(event) {
    try {
        const timeSince0 = Math.round(getCurrentTime() - getInitTime());
        addTime(event, timeSince0);
        // TODO REPLACE WITH LOGGER
        if (isDebug()) {
            console.log('Timing', event, timeSince0);
        }
        return timeSince0;
    }
    catch (e) {
        // prevent errors
        console.error('TimingRecord Error', e);
    }
}
export function difference(eventOne, eventTwo) {
    try {
        const delta = getTimeOf(eventTwo) - getTimeOf(eventOne);
        trackDelta(eventOne, eventTwo, delta);
        return delta;
    }
    catch (e) {
        console.error('Timing Error', e);
    }
}
export function differenceFromRoot(eventName) {
    try {
        return getTimeOf(eventName) - getInitTime();
    }
    catch (e) {
        console.error('Timing error', e);
    }
}
