import { PageContext, Product } from '@fandom/context';
import { VERSION } from './types';
export function pageTrackEntryFromPageContext(plc) {
    return {
        id: `${plc.productId}_${plc.pageId}_${plc.time}`,
        context: plc.toTransport(),
        time: plc.time,
        version: VERSION,
    };
}
export function pageTrackEntryToPageContext(p) {
    // try to recover from earlier versions
    if ((p === null || p === void 0 ? void 0 : p.version) === '1') {
        // `1` was wiki-only
        const p1 = p;
        return PageContext.fromTransport({
            p: Product.MEDIAWIKI,
            pCat: p1.vertical,
            pId: p1.communityId,
            pgId: p1.pageId,
            time: p1.time,
        });
    }
    // unsupported version - return empty
    if ((p === null || p === void 0 ? void 0 : p.version) !== VERSION) {
        return PageContext.getDefault();
    }
    // current version - it's easy
    const pc = p;
    return PageContext.fromTransport(pc.context);
}
