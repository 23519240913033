import { getGeo } from '@fandom/context';
import uniq from 'lodash/uniq';
export default function getCountry(profile) {
    var _a, _b, _c, _d;
    const locations = (_c = (_b = (_a = profile === null || profile === void 0 ? void 0 : profile.namespaces) === null || _a === void 0 ? void 0 : _a.locations) === null || _b === void 0 ? void 0 : _b.set) !== null && _c !== void 0 ? _c : [];
    const fallback = (_d = getGeo()) === null || _d === void 0 ? void 0 : _d.country;
    return uniq([]
        .concat(
    // galactus response
    locations.map((loc) => loc.country), 
    // fallback from cookie
    [fallback])
        .filter(Boolean)
        .map((lang) => lang.toUpperCase()));
}
