import { configureStore } from '@reduxjs/toolkit';
import { isGlobalDebugMode, IS_NOT_PRODUCTION } from '../../utils/env';
import reducer from './reducer';
const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // we need to store onReboot in state, see https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
            ignoredPaths: [
                'App.clients',
                'Context.user',
                'Context.page',
                'BrowserStorage.session.pages',
                'BrowserStorage.history.pages',
            ],
            ignoredActions: ['AppSlice/addClient', 'ContextSlice/updateUser', 'ContextSlice/updatePage'],
        },
    }),
    devTools: {
        name: 'SilverSurfer',
        trace: IS_NOT_PRODUCTION || isGlobalDebugMode(),
    },
});
export default store;
