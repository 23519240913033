// bump this when the galactus API has changed, this will ensure we're not using outdated cached version
export const GALACTUS_API_VERSION = 4;
export const GALACTUS_EMPTY_USER_PROFILE = {
    profileId: null,
    namespaces: {
        adTags: {},
        devices: {
            set: [],
        },
        locations: {
            set: [],
        },
        languages: {
            contentLanguages: [],
            acceptLanguages: [],
        },
    },
};
