import { getServicesBaseURL } from './env';
import fetchWithLogging from './fetchWithLogging';
/** Factory to generate service call function with logging */
export const callFandomServiceFactory = (logErrorFunction) => {
    return (serviceNamePathAndSearch, options = {}) => fetchWithLogging(logErrorFunction, getServicesBaseURL() + serviceNamePathAndSearch, Object.assign({ mode: 'cors', credentials: 'include' }, options));
};
/** Factory to generate fetch functio with logging; used for form upload. */
export const callFandomServiceWithFormDataFactory = (logErrorFunction) => {
    // Don't send 'Content-Type': 'multipart/form-data' header
    // @see https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
    return (serviceNamePathAndSearch, formData, options = {}) => fetchWithLogging(logErrorFunction, getServicesBaseURL() + serviceNamePathAndSearch, Object.assign({ method: 'POST', 
        // headers: { // no header on that kind of call
        // 	'Content-Type': 'application/json',
        // },
        // mode: 'cors', // no cors on that kind of call
        credentials: 'include', body: formData }, options));
};
/** Factory to generate service call function with logging; used to send JSON payload. */
export const callFandomServiceWithBodyFactory = (logErrorFunction) => {
    return (serviceNamePathAndSearch, body, options = {}) => fetchWithLogging(logErrorFunction, getServicesBaseURL() + serviceNamePathAndSearch, Object.assign({ method: 'POST', headers: {
            'Content-Type': 'application/json',
        }, mode: 'cors', credentials: 'include', body: JSON.stringify(body) }, options));
};
