import { __awaiter } from "tslib";
import { getWikiaBeaconId } from '@fandom/context';
import { loadGalactusUserProfile, saveGalactusUserProfile } from 'sdk/storage/storage';
import { getUserProfileFromApi } from 'sdk/api/galactus';
import deepmerge from 'deepmerge';
import store from 'sdk/store/store';
import { configCanSendGalactusRequest, getGalactusUserProfileFromCache } from 'sdk/store/selectors';
import trackSilverSurfer from '../../utils/trackSilverSurfer';
import { safeCancelIdleCallback, safeRequestIdleCallback } from 'utils/safeRequestIdleCallback';
import { cacheGalactusUserProfile } from '../../store/browserStorageSlice';
import log from '../../utils/log';
import runLater from '../../../utils/runLater';
import { GALACTUS_EMPTY_USER_PROFILE } from '../../models/Galactus';
/**
 * 1. Return current object
 * - Check the Redux chache if we have object, return it if we have
 * - Check the browser storage if we have object, cache it in Redux and return it
 * - return nothing
 *
 * 2. Schedule permanent object refresh on next idle window and every 30(?) seconds after that
 * - schedule new idle task to query galactus
 * - once response comes back from Galactus, cache it in browser storage and Redux
 */
const GALACTUS_FETCH_INTERVAL = 60 * 1000; // 1 minute
function schedulePeriodicalgalactusUpdates(beaconId) {
    return __awaiter(this, void 0, void 0, function* () {
        const lastUpdateTime = 0;
        let lastUpdateProfileString = null; // easier to compare string to string
        // do this only when there's time for it, and no more frequently than `GALACTUS_FETCH_INTERVAL`
        const id = safeRequestIdleCallback(() => __awaiter(this, void 0, void 0, function* () {
            // not too frequently
            const currentTime = Date.now();
            if (currentTime - lastUpdateTime < GALACTUS_FETCH_INTERVAL) {
                return;
            }
            // send request to galactus
            try {
                const profile = yield getUserProfileFromApi(beaconId);
                if (profile && (profile === null || profile === void 0 ? void 0 : profile.profileId)) {
                    const profileString = JSON.stringify(profile);
                    // only update if it was different then last one saved
                    if (profileString !== lastUpdateProfileString) {
                        log.debug('getGalactusUserProfile: Profile updated from Galactus', profile);
                        // save profile to Redux
                        store.dispatch(cacheGalactusUserProfile({ beaconId, profile }));
                        // save profile to browser storage
                        saveGalactusUserProfile(beaconId, profile);
                        // update the last updated profile
                        lastUpdateProfileString = profileString;
                    }
                }
            }
            catch (e) {
                log.error('Fail to fetch data from Galactus', e);
                // if we failed calling Galactus, just stop calling it
                safeCancelIdleCallback(id);
            }
        }));
    });
}
let isRefreshScheduled = false;
export function getGalactusUserProfile(beacon) {
    return __awaiter(this, void 0, void 0, function* () {
        // check if there's a beaconId
        const beaconId = beacon || getWikiaBeaconId();
        let userProfile;
        // initialize with what exists in Redux cache
        userProfile = getGalactusUserProfileFromCache(beaconId)(store.getState());
        // try to read fallback, but use default profile as a base
        if (!userProfile) {
            const backupProfileFromStorage = yield loadGalactusUserProfile(beacon);
            if (backupProfileFromStorage) {
                userProfile = deepmerge(GALACTUS_EMPTY_USER_PROFILE, backupProfileFromStorage);
            }
            log.debug('getGalactusUserProfile: Profile read from storage', userProfile);
        }
        else {
            log.debug('getGalactusUserProfile: Profile read from cache', userProfile);
        }
        // refresh schedule when we can
        if (!isRefreshScheduled && configCanSendGalactusRequest(store.getState())) {
            isRefreshScheduled = true;
            runLater(() => schedulePeriodicalgalactusUpdates(beaconId));
        }
        return userProfile;
    });
}
export function getGalactusUserProfileForCurrentUser() {
    return __awaiter(this, void 0, void 0, function* () {
        const beaconId = getWikiaBeaconId();
        let galactusUserProfile = GALACTUS_EMPTY_USER_PROFILE;
        if (!beaconId) {
            trackSilverSurfer.loaded({
                category: 'galactus-user-profile-failed-loaded',
                label: 'no-beacon',
            });
        }
        else {
            galactusUserProfile = yield getGalactusUserProfile(beaconId);
            trackSilverSurfer.loaded({
                category: 'galactus-user-profile-loaded',
                label: `galactus-user-profile-loaded |  ${(galactusUserProfile === null || galactusUserProfile === void 0 ? void 0 : galactusUserProfile.profileId) ? 'true' : 'false'}`,
            });
        }
        return galactusUserProfile;
    });
}
