import { SS_VERSION } from 'utils/env';
import { RegisterMessage } from './types';
export const currentProtocolVersion = SS_VERSION;
export function buildMessage(type, payload) {
    return {
        type,
        timestamp: Date.now(),
        payload,
        xv: currentProtocolVersion,
    };
}
// TODO: bring back protocol check
export function validateMessage(message) {
    return (typeof message.xv === 'string' &&
        typeof message.timestamp === 'number' &&
        message.timestamp > 0 &&
        typeof message.type === 'string');
}
/**
 * Standard messages
 */
export function buildRegisterRequestEvent() {
    return buildMessage(RegisterMessage.Request);
}
export function buildRegisterResponseEvent() {
    return buildMessage(RegisterMessage.Response);
}
