import { getPages } from '../../store/selectors';
import store from '../../store/store';
import { AffiliationType } from '../../types';
// 1st time visitor == 1st visit
// returning visitor == someone with 2 - 5 visits to Fandom
// frequent visitor == has more than 5 visits
function getGeneralAffiliationTypeFromPageViews(pageViewCount) {
    if (pageViewCount === 1) {
        return AffiliationType.FIRST_TIME_VISITOR;
    }
    if (pageViewCount <= 5) {
        return AffiliationType.RETURNING_VISITOR;
    }
    return AffiliationType.FREQUENT_VISITOR;
}
function getGeneralAffiliationType(adTags) {
    // get the number of visited pages and guess from it
    const numberOfPageviews = getPages(store.getState()).length;
    if (numberOfPageviews > 0) {
        return getGeneralAffiliationTypeFromPageViews(numberOfPageviews);
    }
    // fallback - use adtags count (since adtag can exist only once per page)
    const maxAdTagValues = []; // max values for all tags
    Object.values(adTags).forEach((tag) => {
        maxAdTagValues.push(Math.max.apply(null, Object.values(tag)));
    });
    return getGeneralAffiliationTypeFromPageViews(Math.max.apply(null, maxAdTagValues));
}
export default function getAffiliation(adTags) {
    return [getGeneralAffiliationType(adTags)];
}
